import React, { ReactNode, FC } from 'react';
import { enrichAnalytics, withAnalyticOnView } from 'react-shisell';
import { withExtra, withExtras } from 'shisell/extenders';
import styled from 'styled-components';

import { Breadcrumb } from '../../navigation';
import { Breadcrumbs } from '../../navigation/components';

export interface PageLayoutProps {
    pageName: string;
    breadcrumbs?: Breadcrumb[];
    breadcrumbMargin?: string;
    header?: ReactNode;
    body?: ReactNode;
    margin?: string;
    extraData?: Record<string, unknown>;
}

export interface LayoutContainerProps {
    margin?: string;
}

const LayoutContainer = styled.div<LayoutContainerProps>`
    display: flex;
    flex: 1;
    width: inherit;
    flex-direction: column;
    margin: ${(props) => (props.margin ? props.margin : '1.5rem 2rem')};
`;

const BodyContainer = styled.div`
    margin-top: 1.5rem;
    display: flex;
    flex-direction: column;
    flex: 1;
    > div {
        display: flex;
        flex-direction: column;
    }
`;

export const PageLayout: FC<PageLayoutProps> = ({
    breadcrumbs = [],
    header,
    body,
    margin,
    breadcrumbMargin,
}) => (
    <LayoutContainer margin={margin}>
        {breadcrumbs.length > 0 && (
            <Breadcrumbs
                breadcrumbs={breadcrumbs}
                margin={breadcrumbMargin ?? '0 0 1rem 0'}
            />
        )}
        {header}
        <BodyContainer>{body}</BodyContainer>
    </LayoutContainer>
);

const EnhancedPage = withAnalyticOnView<PageLayoutProps>({
    analyticName: 'PageView',
})(PageLayout);

export default enrichAnalytics<PageLayoutProps>(
    (dispatcher, { pageName, extraData = {} }) =>
        dispatcher.extend(withExtra('Page', pageName), withExtras(extraData))
)(EnhancedPage);
