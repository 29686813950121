import { FC } from 'react';
import { flow } from 'fp-ts/lib/function';
import { withAnalyticOnView } from 'react-shisell';

import { useIsLoaded } from './useIsLoaded';
import { Loading } from '../Loading';
import { Routes } from '../Routes';
import {
    useIsAuthenticated,
    withAuthentication,
} from '../../../authentication';
import { withTheme } from '../../../core-ui';
import {
    withContentfulProductProvider,
    withContentfulSecurityProvider,
} from '../../../contentful';
import { withTodaysTipProvider } from '../../../tips';
import { withErrorContextProvider } from '../../../error-handling';
import { withTweekProvider } from '../../../tweek';

type Props = {
    initPromise?: Promise<void>;
};

export const App: FC<Props> = ({ initPromise = Promise.resolve() }) => {
    const isAuthenticated = useIsAuthenticated();
    const isLoaded = useIsLoaded(initPromise);
    return <>{isAuthenticated && isLoaded ? <Routes /> : <Loading />}</>;
};

export default flow(
    withAuthentication,
    withTheme,
    withTweekProvider,
    withTodaysTipProvider,
    withContentfulProductProvider,
    withContentfulSecurityProvider,
    withErrorContextProvider,
    withAnalyticOnView({ analyticName: 'Loaded' })
    // eslint-disable-next-line
)(App as any) as typeof App;
