import {
    Link as RouterLink,
    LinkProps as RouterLinkProps,
} from 'react-router-dom';
import { enrichAnalytics, withAnalyticOnEvent } from 'react-shisell';
import { withExtras } from 'shisell/extenders';

type LinkProps = {
    actionId: string;
    extraData?: Record<string, unknown>;
} & RouterLinkProps;

const Link = withAnalyticOnEvent<'onClick', [], LinkProps>({
    eventName: 'onClick',
    analyticName: 'Click',
})(RouterLink);

export default enrichAnalytics<LinkProps>(
    (dispatcher, { actionId, extraData }) =>
        dispatcher.extend(
            withExtras({
                ActionId: actionId,
                ...extraData,
            })
        )
)(Link);
