import { ITweekStore } from 'tweek-local-cache';

export const createTweekStore = (storageKey = 'tweek-store'): ITweekStore => ({
    save: async (keys) => {
        try {
            localStorage[storageKey] = JSON.stringify(keys);
        } catch (e) {
            console.error('failed to save keys to tweek store', e);
        }
    },
    load: async () => {
        try {
            return JSON.parse(localStorage[storageKey]);
        } catch (e) {
            console.error('failed to load keys from tweek store', e);
            return {};
        }
    },
});
