import React, { FC } from 'react';
import { Composer } from '@soluto-private/content-component-library';
import Vimeo from '@u-wave/react-vimeo';
import { VendorVideo } from '../types';
import { Body, Micro } from '@soluto-private/vds-typography';
import styled from 'styled-components';

const TextContainer = styled.div`
    margin: 1rem 2rem;
`;

const VimeoContainer = styled.div`
    margin: 0 8%;
    @media (max-width: 768px) {
        margin: 0;
    }
`;

const VideoPlayer: FC<{ video: VendorVideo }> = ({ video }) => {
    const videoId = video.fields.brightcoveVideoId;

    return (
        <>
            <Composer vendor="verizon">
                <VimeoContainer>
                    <Vimeo
                        key={videoId}
                        video={videoId}
                        width={640}
                        height={480}
                        responsive={true}
                    />
                </VimeoContainer>
            </Composer>
            <TextContainer>
                <Micro>Playing: </Micro>
                <Body bold size="large">
                    {video.fields.name}
                </Body>
            </TextContainer>
        </>
    );
};

export default VideoPlayer;
