import React from 'react';
import { SecurityContext } from './useContentfulSecurityClient';
import { createContentfulSecurityClient } from './createContentfulSecurityClient';

export const withContentfulSecurityProvider =
    <P extends {}>(Wrapped: React.ComponentType<P>): React.FC<P> =>
    (props: P) =>
        (
            <SecurityContext.Provider
                value={
                    createContentfulSecurityClient().withoutUnresolvableLinks
                }
            >
                <Wrapped {...props} />
            </SecurityContext.Provider>
        );
