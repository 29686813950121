import { Button as VdsButton } from '@soluto-private/vds-buttons';
import { enrichAnalytics, withAnalyticOnEvent } from 'react-shisell';
import { withExtras } from 'shisell/extenders';

type ButtonProps = {
    actionId: string;
    extraData?: Record<string, unknown>;
    onClick?: (e: Event) => void;
    href?: string;
    primary?: boolean;
    secondary?: boolean;
    size?: 'small' | 'large';
    ariaLabel?: string;
};

const Button = withAnalyticOnEvent<'onClick', [], ButtonProps>({
    eventName: 'onClick',
    analyticName: 'onClick',
})(VdsButton);

export default enrichAnalytics<ButtonProps>(
    (dispatcher, { actionId, extraData }) =>
        dispatcher.extend(
            withExtras({
                ActionId: actionId,
                ...extraData,
            })
        )
)(Button);
