import { FC } from 'react';

import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Document } from '@contentful/rich-text-types';
import { Title } from '@soluto-private/vds-typography';
import styled from 'styled-components';
import { useHistory } from 'react-router';

import { GuideStepSkeleton } from '../../types';
import { Button, PaginatedCarousel } from '../../../core-ui';
import { ExpertChatCTA } from '../../../ctas';
import { Entry } from 'contentful';
import { isInV2 } from '../../../utils/isInV2';

const GuideStepContainer = styled.div`
    padding: 0 2rem;
`;

const OverviewContainer = styled.div`
    margin-bottom: 2rem;
`;

const StepContainer = styled.div`
    margin-top: 1.5rem;
`;

const StyledExpertChatContainer = styled.div`
    margin: 2rem 0 1rem 0;
`;

const DoneButtonContainer = styled.div`
    width: 100%;
    display: flex;
    margin: 3rem 0;
    justify-content: center;
`;

type PaginatedGuideProps = {
    guideSteps: (
        | Entry<GuideStepSkeleton, 'WITHOUT_UNRESOLVABLE_LINKS', string>
        | undefined
    )[];
    guideOverview?: Document;
};

export const GuidePaginatedList: FC<PaginatedGuideProps> = ({
    guideSteps,
    guideOverview,
}) => {
    const history = useHistory();
    return (
        <PaginatedCarousel isFullPageSlides>
            {guideSteps.map((guideStep, index, array) => (
                <GuideStepContainer key={`guide-steps-${guideStep?.sys.id}`}>
                    {index === 0 && (
                        <OverviewContainer>
                            <Title size="medium">Overview</Title>
                            {guideOverview &&
                                documentToReactComponents(guideOverview)}
                        </OverviewContainer>
                    )}
                    <Title size="medium">{guideStep?.fields.stepTitle}</Title>
                    <StepContainer>
                        {guideStep &&
                            documentToReactComponents(
                                guideStep.fields.stepDescription
                            )}
                    </StepContainer>
                    {!isInV2() && (
                        <StyledExpertChatContainer>
                            <ExpertChatCTA />
                        </StyledExpertChatContainer>
                    )}
                    {index === array.length - 1 && (
                        <DoneButtonContainer>
                            <Button
                                actionId="CompleteGuideButton"
                                onClick={() => history.goBack()}
                            >
                                Done
                            </Button>
                        </DoneButtonContainer>
                    )}
                </GuideStepContainer>
            ))}
        </PaginatedCarousel>
    );
};
