import { FC } from 'react';
import { Line } from '@soluto-private/vds-lines';
import { Body, Title } from '@soluto-private/vds-typography';
import styled from 'styled-components';

import { BigChatButton } from './BigChatButton';
import { ExpertFaces } from './ExpertFaces';
import { ExperimentVersion } from '../ExperimentVersion';
import { LinkList } from '../../../../../../core-ui';
import { RoutePath } from '../../../../../../navigation';

type HomeBodyProps = {
    showDetails?: boolean;
};

const RowContainer = styled.div`
    margin-bottom: 1rem;
`;

const BodyContainer = styled.div`
    margin-bottom: 1em;
`;

const StyledLine = styled(Line)`
    margin: 0.75em 0;
`;

const StyledList = styled.ul`
    list-style: inside;
    padding: 0 0 0 0.5rem;
`;

export const HomeBody: FC<HomeBodyProps> = ({ showDetails = false }) => (
    <>
        <RowContainer>
            <BodyContainer>
                <Body>
                    We're here to help you protect your identity and your
                    personal data from online threats.
                </Body>
            </BodyContainer>
        </RowContainer>
        <RowContainer>
            <Title size="medium">Chat with a security expert.</Title>
            <StyledLine type="light" />
            <ExpertFaces />
            <Body>
                Get clear guidance, best practices, and tips for many common
                security issues.
                {showDetails && (
                    <StyledList>
                        <li>Securing your Wi-Fi network</li>
                        <li>Help with data breaches</li>
                        <li>Setting up antivirus protection</li>
                        <li>Preventing phishing attacks</li>
                    </StyledList>
                )}
            </Body>
            <BigChatButton
                experimentVersion={ExperimentVersion.ExpertFacesBigChatButton}
            />
        </RowContainer>
        <RowContainer>
            <Title size="medium">Explore the Security Center.</Title>
            <StyledLine type="light" />
            <LinkList
                items={[
                    {
                        actionId: 'SecurityCenterLink',
                        text: 'Get essential security insights',
                        description:
                            'Check out articles, guides, and videos to help you protect your information.',
                        path: RoutePath.SecurityCenter,
                    },
                ]}
            />
        </RowContainer>
    </>
);
