import React, { FC } from 'react';
import styled from 'styled-components';

import { ExperimentVersion } from '../ExperimentVersion';
import { Button } from '../../../../../../core-ui';

export type Props = {
    experimentVersion: ExperimentVersion;
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

const Row = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 0.75rem;
`;

const WrappedButton = styled(Button)`
    font-size: 14px;
    font-weight: normal;
    margin-right: 0.5rem;
    > span + span {
        padding: 0 1rem;
    }
`;

export const ChatCtaButtons: FC<Props> = ({
    experimentVersion: ExperimentVersion,
}) => {
    const analyticExtras = {
        Experiment: 'HomePageChatCtaExperiment',
        ExperimentVersion,
    };
    return (
        <Container>
            <Row>
                <WrappedButton
                    actionId="StartChatButton"
                    extraData={{
                        ...analyticExtras,
                        ChatCtaButton: 'HelpWithDataBreach',
                    }}
                    secondary
                    href={process.env.REACT_APP_CHAT_SMART_LINK}
                >
                    Help with data breach
                </WrappedButton>
                <WrappedButton
                    actionId="StartChatButton"
                    extraData={{
                        ...analyticExtras,
                        ChatCtaButton: 'SecureMyWiFi',
                    }}
                    secondary
                    href={process.env.REACT_APP_CHAT_SMART_LINK}
                >
                    Secure my Wi-Fi
                </WrappedButton>
            </Row>
            <Row>
                <WrappedButton
                    actionId="StartChatButton"
                    extraData={{
                        ...analyticExtras,
                        ChatCtaButton: 'SetUpAntivirus',
                    }}
                    secondary
                    href={process.env.REACT_APP_CHAT_SMART_LINK}
                >
                    Set up antivirus
                </WrappedButton>
                <WrappedButton
                    actionId="StartChatButton"
                    extraData={{
                        ...analyticExtras,
                        ChatCtaButton: 'PreventPhishing',
                    }}
                    secondary
                    href={process.env.REACT_APP_CHAT_SMART_LINK}
                >
                    Prevent phishing
                </WrappedButton>
            </Row>
            <Row>
                <Button
                    actionId="StartChatButton"
                    href={process.env.REACT_APP_CHAT_SMART_LINK}
                    extraData={analyticExtras}
                >
                    Chat with an expert
                </Button>
            </Row>
        </Container>
    );
};
