import React, { FC, ReactElement } from 'react';
import styled from 'styled-components';

export type SideScrollProps = {
    component: ReactElement;
    className?: string;
};

const Container = styled.div`
    display: flex;
    overflow-x: auto;
    padding: 0 2rem;
    ::-webkit-scrollbar {
        display: none;
    }
`;

export const SideScroll: FC<SideScrollProps> = ({ component, className }) => (
    <Container className={className}>{component}</Container>
);
