import { TweekClient } from 'tweek-client';
import { TweekRepository, ITweekStore } from 'tweek-local-cache';

import { UserBrowser } from '../analytics';

export const createTweekRepository = async (
    client: TweekClient,
    store: ITweekStore
) => {
    const repo = new TweekRepository({
        client,
        context: {
            security: {
                id: UserBrowser.get().UserBrowserId,
                partner: 'verizon',
                environment: process.env.REACT_APP_ENV!,
            },
        },
    });

    await repo.useStore(store);

    repo.expire();

    return repo;
};
