import { useEffect, useState } from 'react';

export const useIsLoaded = (initialize: Promise<void>) => {
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        initialize.then(() => setIsLoaded(true));
    }, [initialize]);

    return isLoaded;
};
