import { Link } from '../../core-ui';
import { FC, useEffect } from 'react';
import { GuideCardGrid } from './GuideCardGrid';
import { Body, Title } from '@soluto-private/vds-typography';
import styled from 'styled-components';
import { useGuideSummaries } from '../../guides/hooks';
import { RoutePath } from '../../navigation';

const RecommendedGuidesHeader = styled.div`
    margin: 0 2rem 1.75rem 2rem;
    max-width: 19.5rem;
`;

const BodyTextConainer = styled.div`
    margin-top: 0.5rem;
`;

const RecommendedGuidesContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const ViewAllButtonContainer = styled.div`
    max-width: 19.5rem;
    padding: 1.5rem 2rem 2rem 2rem;
`;

export const RecommendedGuides: FC<{
    setIsLoading: (isLoading: boolean) => void;
}> = ({ setIsLoading }) => {
    const { value, loading } = useGuideSummaries({
        limit: 4,
        additionalParams: {
            'metadata.tags.sys.id[in]': ['vzPopularGuide'],
        },
    });

    useEffect(() => {
        if (!loading) {
            setIsLoading(loading);
        }
    }, [loading, setIsLoading]);

    return (
        <>
            {!loading && value && (
                <RecommendedGuidesContainer>
                    <RecommendedGuidesHeader>
                        <Title size="large">Recommended guides</Title>
                        <BodyTextConainer>
                            <Body>
                                Step-by-step guides to help you protect your
                                information.
                            </Body>
                        </BodyTextConainer>
                    </RecommendedGuidesHeader>
                    <GuideCardGrid guideCardsContent={value} />
                    <ViewAllButtonContainer>
                        <Link to={RoutePath.Guides} actionId="ViewAllGuides">
                            View all guides
                        </Link>
                    </ViewAllButtonContainer>
                </RecommendedGuidesContainer>
            )}
        </>
    );
};
