import { FC } from 'react';
import styled from 'styled-components';

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import { CarouselButtonGroup } from './CarouselButtonGroup';

const carouselBreakpoints = {
    desktop: {
        // 'Desktop' required in the prop for the multi-carousel library
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
        partialVisibilityGutter: 40, // amount in px that should be visible of next item
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
        partialVisibilityGutter: 80, // amount of px that should be visible of next item
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        partialVisibilityGutter: 80, // amount of px that should be visible of next item
    },
};

export type PaginatedCarouselProps = {
    isFullPageSlides?: boolean;
    itemClass?: string;
};

const PaginatedCarouselContainer = styled.div`
    .small-width {
        min-width: 18.375rem !important;
    }
`;

export const PaginatedCarousel: FC<PaginatedCarouselProps> = ({
    children,
    isFullPageSlides = false,
    itemClass,
}) => (
    <PaginatedCarouselContainer>
        <Carousel
            additionalTransfrom={0}
            arrows={false}
            centerMode={false}
            containerClass="container-padding-bottom"
            customButtonGroup={<CarouselButtonGroup />}
            dotListClass=""
            draggable
            focusOnSelect={false}
            infinite={false}
            itemClass={itemClass}
            keyBoardControl
            minimumTouchDrag={80}
            partialVisible={!isFullPageSlides}
            renderButtonGroupOutside
            renderDotsOutside={false}
            responsive={carouselBreakpoints}
            showDots={false}
            sliderClass=""
            slidesToSlide={1}
            swipeable
        >
            {children}
        </Carousel>
    </PaginatedCarouselContainer>
);
