import { EventModelWriter } from 'shisell';

import { fullstoryWriter } from './fullstory';
import { consoleWriter } from './console';
import { apiWriter } from './api';

export * from './fullstory';

export const analyticsWriters: EventModelWriter<void>[] = [
    apiWriter,
    consoleWriter,
    fullstoryWriter,
];
