import { FC } from 'react';
import { Body } from '@soluto-private/vds-typography';
import styled from 'styled-components';

import { ChatSessionMessage } from '../../ChatSessionMessage';

type ChatLineContainerProps = {
    align?: string;
};

const ChatLineContainer = styled.div<ChatLineContainerProps>`
    display: flex;
    flex-direction: column;
    text-align: ${(props) => props.align || 'left'};
    align-items: ${(props) =>
        props.align === 'right' ? 'flex-end' : 'flex-start'};
`;

type BodyContainerProps = {
    isUser?: boolean;
};

const BodyContainer = styled.div<BodyContainerProps>`
    background: ${({ isUser = false, theme }) =>
        isUser ? theme.colors.greys.light : theme.colors.secondary.white}
    border: 1px solid ${({ isUser = false, theme }) =>
        isUser ? theme.colors.greys.light : theme.colors.greys.main};
    border-radius: 20px;
    margin: 0.1rem 0;
    padding: 0.5rem 1rem;
    max-width: 75%;
    width: fit-content;
`;

const SenderContainer = styled.div`
    margin: 1rem 0.5rem 0 0.5rem;
`;

type ChatLineProps = {
    message: ChatSessionMessage;
    isUser?: boolean;
    displaySender?: boolean;
};

export const ChatLine: FC<ChatLineProps> = ({
    message,
    isUser = false,
    displaySender = false,
}) => (
    <ChatLineContainer align={isUser ? 'right' : 'left'}>
        {displaySender && (
            <SenderContainer className="fs-mask">
                <Body bold>{message.sender}</Body>
            </SenderContainer>
        )}
        <BodyContainer className="fs-mask" isUser={isUser}>
            <Body size="large">{message.text}</Body>
        </BodyContainer>
    </ChatLineContainer>
);
