import { FC, useEffect, useState } from 'react';
import { useAsync } from 'react-use';
import { useContentfulProductClient } from '../../contentful';
import { VendorVideo } from '../types';
import VideoPlayer from './VideoPlayer';
import { VideoList } from './VideoList';
import styled from 'styled-components';
import { Line } from '@soluto-private/vds-lines';
import { RoutePath } from '../../navigation';
import { Link } from '../../core-ui';
import { CategoryTags } from '../../categories/config';
import { Loader } from '@soluto-private/vds-loaders';

type VideoSectionProps = {
    shouldLimit?: boolean;
    categoryTag?: CategoryTags;
};

const TextContainer = styled.div`
    margin: 1rem 2rem 2rem 2rem;
`;

export const VideoSection: FC<VideoSectionProps> = ({
    shouldLimit = false,
    categoryTag,
}) => {
    const productContentfulClient = useContentfulProductClient();
    const [selectedVideo, setSelectedVideo] = useState<VendorVideo>();
    const [loading, setLoading] = useState(true);

    const buildEntryFilter = () => {
        // eslint-disable-next-line
        let filter: any = {
            content_type: 'video',
            limit: 100,
            'fields.vendor': 'verizon',
        };
        if (shouldLimit) {
            filter.limit = 4;
        }
        if (categoryTag) {
            filter[
                'metadata.tags.sys.id[all]'
            ] = `vzSecurityAdvisor,${categoryTag}`;
        } else {
            filter['metadata.tags.sys.id[in]'] = [CategoryTags.securityAdvisor];
        }
        return filter;
    };

    const videoArray = useAsync(async () => {
        const videoResponse = await productContentfulClient.getEntries(
            buildEntryFilter()
        );
        return videoResponse.items as unknown as VendorVideo[];
    }, [productContentfulClient]);

    useEffect(() => {
        if (videoArray.value) {
            setSelectedVideo(videoArray.value[0]);
            setLoading(false);
        }
    }, [videoArray]);

    return (
        <div>
            {!loading && videoArray.value && selectedVideo ? (
                <>
                    <VideoPlayer video={selectedVideo} />
                    <TextContainer>
                        <Line type="light" />
                        <VideoList
                            videos={videoArray.value}
                            selectedVideoId={selectedVideo.sys.id}
                            setSelectedVideo={setSelectedVideo}
                        />
                        {shouldLimit && (
                            <Link actionId="ViewAllVideos" to={RoutePath.Video}>
                                View all videos
                            </Link>
                        )}
                    </TextContainer>
                </>
            ) : (
                <Loader active />
            )}
        </div>
    );
};
