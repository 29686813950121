import { FC } from 'react';
import { Line } from '@soluto-private/vds-lines';
import styled from 'styled-components';

import { LinkListItem, LinkListItemProps } from './LinkListItem';

type LinkListProps = {
    items: LinkListItemProps[];
    color?: string;
    displayBottomBorder?: boolean;
};

const LinkListContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const StyledLine = styled(Line)`
    margin: 1em 0;
`;

export const LinkList: FC<LinkListProps> = ({
    items,
    color,
    displayBottomBorder = false,
}) => (
    <LinkListContainer>
        {items.map((item, i) => (
            <div key={`LinkListItem-${i}`}>
                <LinkListItem {...item} color={color} />
                {(i !== items.length - 1 || displayBottomBorder) && (
                    <StyledLine type="xLight" />
                )}
            </div>
        ))}
    </LinkListContainer>
);
