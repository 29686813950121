import React, { FC, useMemo } from 'react';
import styled from 'styled-components';
import { Title } from '@soluto-private/vds-typography';
import { useSecurityContent } from '../../contentful';
import { useAnalytics } from 'react-shisell';
import { QuestionAccordian } from './QuestionAccordian';
import { Entry, EntryFieldTypes } from 'contentful';

export type Question = {
    contentTypeId: 'securityQuestion';
    fields: {
        id: EntryFieldTypes.Symbol;
        title: EntryFieldTypes.Symbol;
        description: EntryFieldTypes.RichText;
    };
};

export type QuestionEntry = Entry<
    Question,
    'WITHOUT_UNRESOLVABLE_LINKS',
    string
>;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    margin: 2rem;
`;

const WrappedTitle = styled.div`
    margin-bottom: 1rem;
`;

export const CommonQuestions: FC = () => {
    const { value } = useSecurityContent<Question>({
        contentType: 'securityQuestion',
    });
    const randomizedQuestions = useMemo(
        () => value?.items.sort(() => Math.random() - 0.5) ?? [],
        [value]
    );
    const { dispatcher } = useAnalytics();
    const onClick = () =>
        dispatcher.dispatch('onClick', {
            ExtraData: {
                ActionId: 'ViewSecurityQuestionDescription',
            },
        });

    return (
        <Container>
            <WrappedTitle>
                <Title size="medium">Common security questions</Title>
            </WrappedTitle>
            <QuestionAccordian
                questions={randomizedQuestions}
                onClick={onClick}
            />
        </Container>
    );
};
