import { FC } from 'react';
import styled from 'styled-components';
import { MarkAsHelpful } from '../../tips/components/MarkAsHelpful';
import { Body, Micro } from '@soluto-private/vds-typography';

export type DidYouKnowTipsCardProps = {
    tipId: string;
    taskDescription: string;
};

const DidYouKnowTipsCardContainer = styled.div`
    height: 11rem;
    padding: 1.5rem 2rem;
`;

const BodyContainer = styled.div`
    margin-top: 0.5rem;
`;

export const DidYouKnowTipsCard: FC<DidYouKnowTipsCardProps> = (props) => (
    <DidYouKnowTipsCardContainer>
        <Micro>Did you know?</Micro>
        <BodyContainer>
            <Body bold>{props.taskDescription}</Body>
        </BodyContainer>
        <MarkAsHelpful
            id={`tip-${props.tipId}`}
            extraData={{ TipId: props.tipId }}
            text="Is this tip helpful?"
        />
    </DidYouKnowTipsCardContainer>
);
