import { FC } from 'react';
import { Line } from '@soluto-private/vds-lines';
import { Loader } from '@soluto-private/vds-loaders';
import { Body, Title } from '@soluto-private/vds-typography';
import styled from 'styled-components';

import { formatChatSessionDate } from '../../formatChatSessionDate';
import { useChatSessions } from '../../hooks';
import { Button, LinkList, PageLayout } from '../../../core-ui';
import { RoutePath } from '../../../navigation';

const TitleContainer = styled.div`
    margin-bottom: 0.5rem;
`;

const StyledLine = styled(Line)`
    margin: 0 0 1.5em 0;
`;

const CenteredContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 4rem;
`;

const StyledButton = styled(Button)`
    margin: 1.5rem 0;
`;

const ChatHistoryHeader: FC = () => (
    <>
        <TitleContainer>
            <Title size="large">Chat history</Title>
        </TitleContainer>
        <Body>Chat history is saved for 90 days.</Body>
    </>
);

const ChatHistoryBody: FC = () => {
    const chatSessions = useChatSessions();

    return !chatSessions ? (
        <Loader active={true} />
    ) : (
        <div>
            <StyledLine />
            {chatSessions.length === 0 && (
                <>
                    <Title size="medium">No chat history found.</Title>
                    <CenteredContainer>
                        <Body>Still need help? Chat with us.</Body>
                        <StyledButton
                            actionId="StartChatButton"
                            href={process.env.REACT_APP_CHAT_SMART_LINK}
                        >
                            Start chat
                        </StyledButton>
                    </CenteredContainer>
                </>
            )}
            <LinkList
                items={chatSessions.map((s) => ({
                    actionId: 'ViewChatSession',
                    extraData: { ChatSessionId: s.id },
                    text: formatChatSessionDate(s.creationTime),
                    path: `${RoutePath.ChatHistory}/${s.id}`,
                }))}
            />
        </div>
    );
};

export const ChatHistoryPage: FC = () => (
    <PageLayout
        pageName="ChatHistoryPage"
        breadcrumbs={[
            { text: 'Security Advisor', path: RoutePath.Home },
            { text: 'Chat history' },
        ]}
        header={<ChatHistoryHeader />}
        body={<ChatHistoryBody />}
    />
);
