import { FC } from 'react';
import { Body, Title } from '@soluto-private/vds-typography';
import styled from 'styled-components';

import UserQuestion from './user-question.svg';
import { Button } from '../../core-ui';

const ExpertChatCTACard = styled.div`
    display: flex;
    flex-direction: row!;
    background-color: ${({ theme }) => theme.colors.greys.light};
    border-radius: 0.5em;
    padding: 1em;
`;

const LeftColumn = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
`;

const RightColumn = styled.div`
    display: flex;
    flex-direction: column;
`;

const BodyContainer = styled.div`
    margin: 0.5em 0 1em 0;
`;

export const ExpertChatCTA: FC = () => (
    <ExpertChatCTACard>
        <LeftColumn>
            <Title size="medium">Feeling stuck?</Title>
            <BodyContainer>
                <Body>Our experts can help.</Body>
            </BodyContainer>
            <Button
                actionId="FeelingStuckExpertChatCTA"
                size="small"
                href={process.env.REACT_APP_CHAT_SMART_LINK}
            >
                Chat now
            </Button>
        </LeftColumn>
        <RightColumn>
            <img src={UserQuestion} alt="Chat with an expert" />
        </RightColumn>
    </ExpertChatCTACard>
);
