import { FC } from 'react';
import styled from 'styled-components';

import expertFaces from './expert-faces.svg';

const StyledImage = styled.img`
    display: flex;
    align-self: center;
    width: 75%;
    margin-bottom: 0.5rem;
`;

export const ExpertFaces: FC = () => (
    <StyledImage src={expertFaces} alt="Expert faces" />
);
