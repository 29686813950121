import { FC, memo, SyntheticEvent, useCallback, useEffect } from 'react';
import { Tabs, Tab } from '@soluto-private/vds-tabs';
import styled from 'styled-components';

import { pageSectionTabs } from '../config';
import { theme } from '../../core-ui';
import { isElementInViewport } from '../../utils';

export type SectionTabsProps = {
    activeTab: number;
    setActiveTab: (tab: number) => void;
};

export const generateSectionId = (sectionIndex: number) =>
    `pageSection_${pageSectionTabs[sectionIndex].name}`;

const SectionTabsContainer = memo(styled.div<{ activeTabId: string }>`
    position: fixed;
    z-index: 999;
    background: ${theme.colors.secondary.white};
    margin-bottom: 1.5rem;
    max-width: 100%;
    overflow: auto;
    // adds padding to the tab container
    > div > span > span > div > div > div > ul {
        padding: 0rem 0.25rem;
        > #${({ activeTabId }) => activeTabId} > button {
            color: ${theme.colors.secondary.black};
            border-bottom-color: ${theme.colors.primary};
        }
    }
    ::-webkit-scrollbar {
        display: none;
    }
`);

const TabsWrapper: FC<{
    onSectionTabChange: (event: SyntheticEvent, tabIndex: number) => void;
}> = memo(({ onSectionTabChange }) => (
    <Tabs
        indicatorPosition="bottom"
        linePosition="bottom"
        selectedIndex={0}
        onTabChange={onSectionTabChange}
        maxWidth="100vw"
    >
        {pageSectionTabs.map((tab) => (
            <Tab key={tab.name} label={tab.label} uniqueId={tab.name} />
        ))}
    </Tabs>
));

export const SectionTabs: FC<SectionTabsProps> = memo(
    ({ activeTab, setActiveTab }) => {
        const onSectionTabChange = useCallback(
            (_: SyntheticEvent, tabIndex: number) => {
                document
                    .getElementById(generateSectionId(tabIndex))
                    ?.scrollIntoView({
                        block: 'start',
                        inline: 'nearest',
                    });
                setActiveTab(tabIndex);
            },
            [setActiveTab]
        );

        useEffect(() => {
            const tabId = `tab_${pageSectionTabs[activeTab].name}`;
            const element = window.document.getElementById(tabId);
            if (element && !isElementInViewport(element)) {
                document.getElementById(tabId)?.scrollIntoView({
                    inline: 'start',
                });
            }
        }, [activeTab]);

        return (
            <SectionTabsContainer
                activeTabId={`tab_${pageSectionTabs[activeTab].name}`}
            >
                <TabsWrapper onSectionTabChange={onSectionTabChange} />
            </SectionTabsContainer>
        );
    }
);
