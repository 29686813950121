import { useEffect, useState } from 'react';
import { useInterval } from 'react-use';
import { AsyncStateRetry } from 'react-use/lib/useAsyncRetry';
import { useErrorContext } from './useErrorContext';

type ErrorHandlingOptions = {
    state: AsyncStateRetry<unknown>;
    message?: string;
    retries?: number;
    retryIntervalMs?: number;
    showErrorPage?: boolean;
};

export const useErrorHandling = ({
    state,
    message,
    retries = 3,
    retryIntervalMs = 1000,
    showErrorPage = true,
}: ErrorHandlingOptions) => {
    const { setError } = useErrorContext();
    const [remainingRetries, setRemainingRetries] = useState(retries);

    useEffect(() => {
        if (state.error && !state.loading && remainingRetries <= 0) {
            console.error(
                message ?? 'Caught error, routing to error page.',
                state.error
            );
            if (showErrorPage) {
                setError(state.error);
            }
        }
    }, [state, message, remainingRetries, showErrorPage, setError]);

    useInterval(
        () => {
            if (remainingRetries > 0) {
                state.retry();
                setRemainingRetries((prev) => prev - 1);
            }
        },
        state.error && !state.loading && remainingRetries > 0
            ? retryIntervalMs
            : null
    );
};
