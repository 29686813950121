import { FC } from 'react';
import { Line } from '@soluto-private/vds-lines';
import { Title, Body } from '@soluto-private/vds-typography';
import styled from 'styled-components';

import { CompletedTip, UncompletedTip } from './index';
import { useTodaysTip } from '../useTodaysTip';

import { PageLayout } from '../../core-ui';
import { RoutePath } from '../../navigation';

const TitleContainer = styled.div`
    margin-top: 1.5rem;
`;
const WrappedBody = styled.div`
    margin-top: 0.75rem;
`;

const WrappedLine = styled(Line)`
    margin-top: 0.75rem;
`;

const Header: FC = () => (
    <TitleContainer>
        <Title size="large">Todays tip</Title>
        <WrappedBody>
            <Body size="small">
                A quick way to take action and keep your information safe.
            </Body>
        </WrappedBody>
        <WrappedLine type="heavy"></WrappedLine>
    </TitleContainer>
);

const TipSection: FC = () => {
    const { todaysTip, isTipCompleted, setTipCompleted } = useTodaysTip();

    return (
        <>
            {todaysTip && isTipCompleted ? (
                <CompletedTip tip={todaysTip} />
            ) : (
                todaysTip && (
                    <UncompletedTip
                        tip={todaysTip}
                        onClick={() => setTipCompleted()}
                    />
                )
            )}
        </>
    );
};

export const TodaysTipPage: FC = () => (
    <PageLayout
        pageName="TodaysTip"
        header={<Header />}
        breadcrumbs={[
            { text: 'Security center', path: RoutePath.SecurityCenter },
            { text: "Today's tip" },
        ]}
        body={<TipSection />}
    />
);
