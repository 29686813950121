import { useAsync } from 'react-use';
import { ContentfulResponse } from '@soluto-private/content-component-library';

export const useContentfulProductArticle = (articleId: string) =>
    useAsync(async () => {
        const url = new URL(
            `${process.env.REACT_APP_CONTENTFUL_WRAPPER!}/article`
        );
        url.searchParams.append('id', articleId);
        const response = await fetch(url.toString());
        const body = await response.json();
        return body as ContentfulResponse;
    }, [articleId]);
