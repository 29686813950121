import { useAsyncRetry } from 'react-use';

import { ChatSession } from '../ChatSession';
import { useErrorHandling } from '../../error-handling';
import { useAuthentication } from '../../authentication';

export const useChatSessions = () => {
    const { accessToken } = useAuthentication();

    const state = useAsyncRetry(async () => {
        if (accessToken) {
            const url = `${process.env.REACT_APP_SECURITY_API}/ds-chat-sessions/sessions`;
            const opts: RequestInit = {
                method: 'GET',
                headers: { authorization: `Bearer ${accessToken}` },
            };
            const response = await fetch(url, opts);
            if (!response.ok) {
                throw new Error(
                    `Failed to fetch chat sessions: ${response.statusText}`
                );
            }
            const sessions = (await response.json()) as Record<
                string,
                unknown
            >[];
            return sessions.map(
                ({ id, creationTime }) => ({ id, creationTime } as ChatSession)
            );
        }
    }, [accessToken]);

    useErrorHandling({ state });

    return state.value;
};
