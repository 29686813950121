import { GuideSummary } from '../types/GuideSummary';
import { ContentParams, useSecurityContent } from '../../contentful';

type Params = Omit<ContentParams, 'contentType'>;

export const useGuideSummaries = (params: Params = {}) =>
    useSecurityContent<GuideSummary>({
        contentType: 'securityGuideSummary',
        ...params,
    });
