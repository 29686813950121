import { LinkListItemProps } from '../../core-ui';
import { RoutePath } from '../../navigation';
import { ArticleEntry } from '../Article';

export const formatArticles = (
    value: ArticleEntry[] | undefined,
    tags?: string[]
) =>
    value?.map(
        ({ sys, fields }) =>
            ({
                text: fields.title,
                actionId: 'ViewArticle',
                extraData: { ArticleId: sys.id, ArticleTags: tags },
                path: `${RoutePath.Articles}/${sys.id}`,
            } as LinkListItemProps)
    );
