import { FC, ComponentType } from 'react';
import { ThemeProvider } from 'styled-components';

import { theme } from './theme';

export const withTheme =
    <Props extends Record<string, never>>(
        ChildComponents: ComponentType<Props>
    ): FC<Props> =>
    (props: Props) =>
        (
            <ThemeProvider theme={theme}>
                <ChildComponents {...props} />
            </ThemeProvider>
        );
