import { FC, MouseEventHandler } from 'react';
import { withAnalyticOnEvent } from 'react-shisell';
import styled from 'styled-components';

import ChatBubble from './chat-bubble.svg';
import { noop } from '../../utils';
import { withExtras } from 'shisell/extenders';

type ChatWithExpertProps = {
    onClick?: MouseEventHandler;
};

const ChatWithExpertButton = styled.div`
    position: fixed;
    bottom: 1.25rem;
    right: 3.25rem;
    border-radius: 50%;
    width: 2.125rem;
    height: 2.125rem;
    line-height: 2.375rem;
    background: ${({ theme }) => theme.colors.secondary.black};
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const ChatImg = styled.img`
    width: 64px;
`;

const ChatWithExpert: FC<ChatWithExpertProps> = ({ onClick = noop }) => (
    <a href={process.env.REACT_APP_CHAT_SMART_LINK!} onClick={onClick}>
        <ChatWithExpertButton>
            <ChatImg
                src={ChatBubble}
                alt="Chat button"
                aria-label="Chat button"
            />
        </ChatWithExpertButton>
    </a>
);

export default withAnalyticOnEvent({
    eventName: 'onClick',
    analyticName: 'Click',
    extendAnalytics: () => withExtras({ ActionId: 'ChatWithExpert' }),
})(ChatWithExpert);
