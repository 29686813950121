import { useEffect, useState } from 'react';

import { AuthenticationContext } from '../context';
import { SecurityAdvisorBridge, SecurityAdvisorBridgeV2 } from '../../bridge';
import { isInV2 } from '../../utils/isInV2';

export const withAuthentication =
    <P extends Record<string, never>>(
        Wrapped: React.ComponentType<P>
    ): React.FC<P> =>
    (props: P) => {
        const [accessToken, setAccessToken] = useState<string>();
        const bridge = isInV2()
            ? SecurityAdvisorBridgeV2
            : SecurityAdvisorBridge;

        useEffect(() => {
            const subscription$ = bridge.accessToken$.subscribe(setAccessToken);
            return () => subscription$.unsubscribe();
        }, [setAccessToken, bridge.accessToken$]);

        return (
            <AuthenticationContext.Provider value={{ accessToken }}>
                <Wrapped {...props} />
            </AuthenticationContext.Provider>
        );
    };
