import React, { FC } from 'react';

type Props = {
    color: string;
};

export const ThumbsDown: FC<Props> = ({ color }) => (
    <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M17.2582 0H10.9522V1.104H2.78919C1.66019 1.104 0.742188 2.016 0.742188 3.138V9.68C0.742188 10.802 1.66019 11.714 2.78919 11.714H8.35219V16.154C8.35219 16.693 8.58819 17.203 9.00119 17.554C9.34819 17.848 9.77019 18 10.2002 18C10.4652 18 10.7342 17.942 10.9892 17.824C11.6502 17.518 12.0592 16.879 12.0592 16.154V11.41H17.2572V0H17.2582ZM10.9522 16.155C10.9522 16.446 10.7912 16.694 10.5242 16.818C10.2492 16.945 9.94819 16.904 9.72019 16.71C9.55619 16.571 9.46119 16.368 9.46119 16.155V10.607H2.78919C2.27219 10.607 1.85019 10.191 1.85019 9.681V9.37H5.26219V8.262H1.85019V6.881H5.26219V5.773H1.85019V4.57H5.26219V3.461H1.85019V3.138C1.85019 2.628 2.27119 2.212 2.78919 2.212H10.9512C10.9522 7.226 10.9522 11.119 10.9522 16.155ZM16.1502 10.302H12.0602C12.0602 10.099 12.0602 0.961 12.0602 1.108H16.1502V10.302Z"
            fill={color}
        />
    </svg>
);
