import React, { FC } from 'react';
import { useMarkAsHelpful } from '../useMarkAsHelpful';
import styled from 'styled-components';
import { Micro } from '@soluto-private/vds-typography';
import { ButtonWrapper } from '../../core-ui';
import { theme } from '../../core-ui/Theme/theme';
import { ThumbsUp } from './ThumbsUp';
import { ThumbsDown } from './ThumbsDown';

const RowContainer = styled.div`
    display: flex;
    margin-top: 1rem;
`;

const ButtonContainer = styled.div`
    margin-left: 1rem;
`;

type Props = {
    id: string;
    text: string;
    extraData?: Record<string, unknown>;
};

export const MarkAsHelpful: FC<Props> = ({ id, text, extraData }) => {
    const { wasHelpful, setWasHelpful } = useMarkAsHelpful(id);

    return (
        <RowContainer>
            <Micro>{text}</Micro>
            <ButtonContainer>
                <ButtonWrapper
                    actionId="MarkAsHelpful"
                    extraData={extraData}
                    onClick={() => setWasHelpful(true)}
                >
                    <ThumbsUp
                        color={
                            wasHelpful === true
                                ? theme.colors.secondary.black
                                : theme.colors.greys.dark
                        }
                    />
                </ButtonWrapper>
            </ButtonContainer>
            <ButtonContainer>
                <ButtonWrapper
                    actionId="MarkAsNotHelpful"
                    extraData={extraData}
                    onClick={() => setWasHelpful(false)}
                >
                    <ThumbsDown
                        color={
                            wasHelpful === false
                                ? theme.colors.secondary.black
                                : theme.colors.greys.dark
                        }
                    />
                </ButtonWrapper>
            </ButtonContainer>
        </RowContainer>
    );
};
