import { FC } from 'react';
import { Loader } from '@soluto-private/vds-loaders';
import { Title } from '@soluto-private/vds-typography';
import styled from 'styled-components';

const LoadingContainer = styled.div`
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;

const TitleContainer = styled.div`
    text-align: center;
    margin: 10.25rem 0rem 2rem 0rem;
`;

const StyledLoader = styled(Loader)`
    background-color: transparent;
    height: 2.5rem;
    width: 2.5rem;
    position: relative;
`;

export const Loading: FC = () => (
    <LoadingContainer>
        <TitleContainer>
            <Title size="XLarge">Security Advisor</Title>
        </TitleContainer>
        <StyledLoader active={true} />
    </LoadingContainer>
);
