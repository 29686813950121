import { FC, MutableRefObject, useRef, useState } from 'react';
import { Title } from '@soluto-private/vds-typography';
import styled from 'styled-components';

import {
    generateSectionId,
    SectionTabs,
    SectionTabsProps,
} from './security-center-ui/SectionTabs';
import { SectionNames } from './config';
import { CommonQuestions } from './security-center-ui/CommonQuestions';
import { useActiveTab } from './useActiveTab';
import { VideoSection } from '../video';
import { PageLayout } from '../core-ui/PageLayout/PageLayout';
import { RecommendedGuides } from './security-center-ui/RecommendedGuides';
import { TodaysTipCard } from '../tips/components';
import { DidYouKnowTips } from './security-center-ui';
import { PopularArticles } from './security-center-ui';
import { Breadcrumb, RoutePath } from '../navigation';
import { Breadcrumbs } from '../navigation/components';
import { Loader } from '@soluto-private/vds-loaders';

const SecurityCenterPageContainer = styled.div``;

const PageBreak = styled.div`
    width: 100vw;
    height: 0.75rem;
    background-color: ${({ theme }) => theme.colors.greys.main};
`;

const StyledTitleContainer = styled.div`
    margin: 1.5rem 2rem 1rem 2rem;
`;

type PageSectionProps = {
    className?: string;
    sectionRef: MutableRefObject<HTMLDivElement>;
    id: string;
};

const PageSection: FC<PageSectionProps> = ({
    children,
    sectionRef,
    id,
    className,
}) => (
    <div className={className} ref={sectionRef} id={id}>
        {children}
    </div>
);

/*
    NOTE: this is required to enable smooth scrolling with a fixed header in safari,
    a cleaner solution would be to use scroll-margin property which is currently
    not supported by safari
*/
const StyledPageSection = styled(PageSection)`
    padding-top: 2em;
    margin-top: -2em;
`;

type SecurityCenterHeaderProps = SectionTabsProps;

const bc: Breadcrumb[] = [
    { text: 'Security Advisor', path: RoutePath.Home },
    { text: 'Security center' },
];

const SecurityCenterHeader: FC<SecurityCenterHeaderProps> = (props) => (
    <div>
        <SectionTabs {...props} />
        <Breadcrumbs breadcrumbs={bc} margin="4.125rem 0 0 2rem" />
    </div>
);

export const SecurityCenterPage: FC = () => {
    const refs = [
        useRef<HTMLDivElement>(),
        useRef<HTMLDivElement>(),
        useRef<HTMLDivElement>(),
        useRef<HTMLDivElement>(),
        useRef<HTMLDivElement>(),
        useRef<HTMLDivElement>(),
    ] as MutableRefObject<HTMLDivElement>[];
    const [
        guidesRef,
        commonQuestionsRef,
        todaysTipRef,
        articlesRef,
        videosRef,
        didYouKnowRef,
    ] = refs;
    const { activeTab, setActiveTab } = useActiveTab(refs);
    const [isLoading, setIsLoading] = useState(true);

    setTimeout(() => setIsLoading(false), 5000);
    return (
        <PageLayout
            pageName="Security Center"
            header={
                <SecurityCenterHeader
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                />
            }
            margin="0rem 0rem 8rem 0rem"
            body={
                <SecurityCenterPageContainer>
                    <StyledPageSection
                        sectionRef={guidesRef}
                        id={generateSectionId(SectionNames.Guides)}
                    >
                        <RecommendedGuides setIsLoading={setIsLoading} />
                    </StyledPageSection>
                    <PageBreak />
                    {isLoading ? (
                        <Loader active={isLoading} />
                    ) : (
                        <>
                            <StyledPageSection
                                sectionRef={commonQuestionsRef}
                                id={generateSectionId(
                                    SectionNames.CommonQuestions
                                )}
                            >
                                <CommonQuestions />
                            </StyledPageSection>
                            <PageBreak />
                            <StyledPageSection
                                sectionRef={todaysTipRef}
                                id={generateSectionId(SectionNames.TodaysTip)}
                            >
                                <TodaysTipCard />
                            </StyledPageSection>
                            <PageBreak />
                            <StyledPageSection
                                sectionRef={articlesRef}
                                id={generateSectionId(SectionNames.Articles)}
                            >
                                <PopularArticles />
                            </StyledPageSection>
                            <PageBreak />
                            <StyledPageSection
                                sectionRef={videosRef}
                                id={generateSectionId(SectionNames.Videos)}
                            >
                                <StyledTitleContainer>
                                    <Title size="medium">Videos</Title>
                                </StyledTitleContainer>
                                <VideoSection shouldLimit />
                            </StyledPageSection>
                            <PageBreak />
                            <StyledPageSection
                                sectionRef={didYouKnowRef}
                                id={generateSectionId(SectionNames.DidYouKnow)}
                            >
                                <DidYouKnowTips />
                            </StyledPageSection>
                        </>
                    )}
                </SecurityCenterPageContainer>
            }
        />
    );
};
