import { FC } from 'react';
import styled from 'styled-components';
import { Title, Body } from '@soluto-private/vds-typography';
import Icon from '@soluto-private/vds-icons';
import { ButtonWrapper } from '../Button/ButtonWrapper';

type ActionCardProps = {
    title?: string;
    onClick: () => void;
    actionId: string;
    extraData?: Record<string, unknown>;
    titleSize?: string;
    iconSize?: string;
    body?: string;
    bodyBold?: boolean;
    bodyMargin?: string;
    className?: string;
};

type StyledActionCardProps = {
    body?: string;
    color?: string;
};

type BodyContainerProps = {
    margin?: string;
};

const StyledActionCard = styled.div<StyledActionCardProps>`
    display: flex;
    flex-direction: ${(props) => (props.body ? 'column' : 'row')};
    align-items: ${(props) => (props.body ? '' : 'center')}
    justify-content: ${(props) => (props.body ? '' : 'space-between')}
`;

const BodyContainer = styled.div<BodyContainerProps>`
    display: flex;
    justify-content: space-between;
    margin: ${(props) => (props.margin ? props.margin : '0rem')};
    align-items: center;
`;

export const ActionCard: FC<ActionCardProps> = ({
    onClick,
    title,
    titleSize,
    body,
    bodyBold,
    actionId,
    extraData,
    iconSize,
    className,
    bodyMargin,
}) => (
    <ButtonWrapper onClick={onClick} actionId={actionId} extraData={extraData}>
        <StyledActionCard body={body} className={className}>
            <Title size={titleSize ?? 'medium'}>{title}</Title>
            <BodyContainer margin={bodyMargin}>
                {body && (
                    <Body size="small" bold={bodyBold}>
                        {body}
                    </Body>
                )}
                <Icon name="caret-right" size={iconSize ?? 'large'}></Icon>
            </BodyContainer>
        </StyledActionCard>
    </ButtonWrapper>
);
