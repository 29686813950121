import { useEffect } from 'react';
import { Switch as RRDSwitch } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { isInV2 } from '../../../utils/isInV2';

type Props = React.ComponentProps<typeof RRDSwitch>;

const isV2 = isInV2();

const postAnalytics = (pathName: string) => {
    const analyticsApi = process.env.REACT_APP_SECURITY_CENTER_API;

    if (!analyticsApi) {
        return;
    }

    fetch(`${analyticsApi}/verizon-analytics`, {
        method: 'POST',
        body: JSON.stringify({ pathName }),
    });
};

export const AnalyticsSwitch = ({ children }: Props) => {
    const location = useLocation();

    useEffect(() => {
        try {
            postAnalytics(location.pathname);
        } catch (e) {
            console.error(e);
        }
    }, [location]);

    return <RRDSwitch>{children}</RRDSwitch>;
};

export const BasicSwitch = ({ children }: Props) => (
    <RRDSwitch>{children}</RRDSwitch>
);

export const Switch = isV2 ? AnalyticsSwitch : BasicSwitch;
