import { FC, useEffect, useState } from 'react';
import { Line } from '@soluto-private/vds-lines';
import { Title } from '@soluto-private/vds-typography';
import styled from 'styled-components';
import { Loader } from '@soluto-private/vds-loaders';

import { useArticles } from '../../hooks';
import { LinkList, LinkListItemProps, PageLayout } from '../../../core-ui';
import { RoutePath } from '../../../navigation';
import { CategoryTags } from '../../../categories/config';
import { Article, ArticleEntry } from '../../Article';
import { formatArticles } from '../utils';

const StyledLine = styled(Line)`
    margin: 0 0 1.5em 0;
`;

const ArticlesHeader: FC = () => <Title size="large">All articles</Title>;

const ArticlesBody: FC = () => {
    const { value, loading } = useArticles<Article>({
        additionalParams: {
            'metadata.tags.sys.id[in]': [CategoryTags.securityAdvisor],
        },
    });
    const [articles, setArticles] = useState<ArticleEntry[]>();
    const [formattedArticles, setFormattedArticles] =
        useState<LinkListItemProps[]>();

    useEffect(() => {
        setArticles(value);
    }, [value, loading]);

    useEffect(() => {
        setFormattedArticles(formatArticles(articles));
    }, [articles]);

    return (
        <div>
            <StyledLine />
            <Loader active={loading} />
            <LinkList items={formattedArticles ?? []} />
        </div>
    );
};

export const ArticlesPage: FC = () => (
    <PageLayout
        pageName="ArticlesPage"
        breadcrumbs={[
            { text: 'Security Advisor', path: RoutePath.SecurityCenter },
            { text: 'All articles' },
        ]}
        header={<ArticlesHeader />}
        body={<ArticlesBody />}
    />
);
