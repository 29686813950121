export const isElementInViewport = (e: HTMLElement) => {
    const { top, left, bottom, right } = e.getBoundingClientRect();

    return (
        top >= 0 &&
        left >= 0 &&
        bottom <=
            (window.innerHeight || document.documentElement.clientHeight) &&
        right <= (window.innerWidth || document.documentElement.clientWidth)
    );
};
