import { ContentfulClientApi } from 'contentful';
import { createContext, useContext } from 'react';

export const ProductContentfulContext = createContext<
    ContentfulClientApi<'WITHOUT_UNRESOLVABLE_LINKS'> | undefined
>(undefined);

export const useContentfulProductClient = () => {
    const client = useContext(ProductContentfulContext);
    if (!client) {
        throw new Error('Contentful client not initialized');
    }
    return client;
};
