import React from 'react';
import { ProductContentfulContext } from './useContentfulProductClient';
import { createContentfulProductClient } from './createContentfulClient';

export const withContentfulProductProvider =
    <P extends {}>(Wrapped: React.ComponentType<P>): React.FC<P> =>
    (props: P) =>
        (
            <ProductContentfulContext.Provider
                value={createContentfulProductClient().withoutUnresolvableLinks}
            >
                <Wrapped {...props} />
            </ProductContentfulContext.Provider>
        );
