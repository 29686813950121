import { FC } from 'react';
import { Line } from '@soluto-private/vds-lines';
import { Body, Title } from '@soluto-private/vds-typography';
import styled from 'styled-components';

import { ChatCtaButtons } from './ChatCtaButtons';
import { ExperimentVersion } from '../ExperimentVersion';
import { HomeHeader } from '../../../HomeHeader';
import { LinkList, PageLayout } from '../../../../../../core-ui';
import { RoutePath } from '../../../../../../navigation';

const RowContainer = styled.div`
    margin-bottom: 2.625rem;
`;

const BodyContainer = styled.div`
    margin-bottom: 1.5em;
`;

const StyledLine = styled(Line)`
    margin: 0.75em 0;
`;

const HomeBody: FC = () => (
    <>
        <RowContainer>
            <BodyContainer>
                <Body>
                    We're here to help you protect your identity and your
                    personal data from online threats.
                    <br />
                    <br />
                    How can we help you today?
                </Body>
            </BodyContainer>
            <ChatCtaButtons
                experimentVersion={ExperimentVersion.TextOnlyChatButtonsTop}
            />
        </RowContainer>
        <RowContainer>
            <Title size="medium">Explore</Title>
            <StyledLine type="light" />
            <LinkList
                items={[
                    {
                        actionId: 'SecurityCenterLink',
                        text: 'Security center',
                        description:
                            'Articles, guides, and videos to help you navigate digital security.',
                        path: RoutePath.SecurityCenter,
                    },
                    {
                        actionId: 'ChatHistoryLink',
                        text: 'Chat history',
                        description: 'Find past conversations.',
                        path: RoutePath.ChatHistory,
                    },
                ]}
            />
        </RowContainer>
    </>
);

const HomePage: FC = () => (
    <PageLayout
        pageName="TextOnlyChatButtonsTopHomePage"
        header={<HomeHeader />}
        body={<HomeBody />}
        extraData={{
            Experiment: 'HomePageChatCtaExperiment',
            ExperimentVersion: ExperimentVersion.TextOnlyChatButtonsTop,
        }}
    />
);

export default HomePage;
