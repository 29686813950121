import { FC, useEffect } from 'react';
import { Title } from '@soluto-private/vds-typography';
import styled from 'styled-components';
import { Button } from '../core-ui';
import { PageLayout } from '../core-ui/PageLayout/PageLayout';
import { useErrorContext } from './useErrorContext';

const ButtonContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 1;
`;
const ButtonWithMargin = styled(Button)`
    margin-bottom: 1rem;
`;

const ErrorHeader: FC = () => (
    <Title size="2XLarge">Sorry, we were unable to load this page.</Title>
);

const ErrorBody: FC = () => {
    const { setError } = useErrorContext();

    useEffect(() => {
        // clear error state upon visiting error page
        setError(undefined);
    }, [setError]);

    return (
        <>
            <ButtonContainer>
                <ButtonWithMargin
                    actionId="Refresh"
                    href={process.env.REACT_APP_REFRESH_SMART_LINK}
                >
                    Refresh
                </ButtonWithMargin>
                <Button
                    secondary
                    actionId="GoBack"
                    href={process.env.REACT_APP_EXIT_SMART_LINK}
                >
                    Go back home
                </Button>
            </ButtonContainer>
        </>
    );
};

export const ErrorPage: FC = () => (
    <PageLayout
        pageName="ErrorPage"
        header={<ErrorHeader />}
        body={<ErrorBody />}
        margin="4rem 2rem"
    />
);
