import React, { FC } from 'react';

type Props = {
    color: string;
};

export const ThumbsUp: FC<Props> = ({ color }) => (
    <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M15.1555 6.28698H9.64355V1.84398C9.64355 1.30598 9.40955 0.796975 9.00055 0.446975C8.44955 -0.025025 7.69555 -0.133025 7.03355 0.172975C6.37355 0.475975 5.96255 1.11797 5.96255 1.84398V6.58998H0.810547V18H7.06755V16.896H15.1555C16.2755 16.896 17.1875 15.984 17.1875 14.864V8.31898C17.1885 7.19798 16.2755 6.28698 15.1555 6.28698ZM5.96355 16.896H1.91455V7.69397H5.96355C5.96355 7.89698 5.96355 17.045 5.96355 16.896ZM15.1555 15.792H7.06755C7.06755 10.776 7.06755 6.88198 7.06755 1.84398C7.06755 1.54898 7.22755 1.29798 7.49555 1.17498C7.76355 1.04998 8.05755 1.09098 8.28255 1.28498C8.44555 1.42397 8.53955 1.62798 8.53955 1.84398V7.39198H15.1555C15.6675 7.39198 16.0835 7.80798 16.0835 8.31898V8.63298H12.7045V9.73698H16.0835V11.121H12.7045V12.225H16.0835V13.433H12.7045V14.537H16.0835V14.864C16.0845 15.376 15.6675 15.792 15.1555 15.792Z"
            fill={color}
        />
    </svg>
);
