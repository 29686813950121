import { FC } from 'react';
import styled from 'styled-components';

import { Item, ItemProps } from './Item';

type LinkListProps = {
    items: ItemProps[];
};

const ListContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const ItemList: FC<LinkListProps> = ({ items }) => (
    <ListContainer>
        {items.map((item, i) => (
            <Item key={`Item-${i}`} {...item} />
        ))}
    </ListContainer>
);
