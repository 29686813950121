export const theme = {
    breakpoints: {
        mobileSmall: 320,
        mobileMedium: 375,
        mobileLarge: 425,
        tablet: 768,
        desktop: 1024,
        desktopLarge: 1440,
    },
    colors: {
        primary: '#D52B1E',
        secondary: {
            white: '#fff',
            black: '#000',
        },
        greys: {
            light: '#F6F6F6',
            main: '#D8DADA',
            dark: '#747676',
            veryDark: '#333333',
        },
    },
};
