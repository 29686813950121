import { FC, useEffect, useState } from 'react';
import { Line } from '@soluto-private/vds-lines';
import { Body, Title } from '@soluto-private/vds-typography';
import styled from 'styled-components';
import { Loader } from '@soluto-private/vds-loaders';

import { useGuideSummaries } from '../../hooks';
import { LinkList, PageLayout, theme } from '../../../core-ui';
import { RoutePath } from '../../../navigation';
import { Breadcrumbs } from '../../../navigation/components';
import { GuideSummaryEntry } from '../../types/GuideSummary';

const GuidesHeaderContainer = styled.div`
    padding: 0rem 2rem;
`;

const StyledLine = styled(Line)`
    margin: 1rem 0rem 1.5rem 0rem;
`;

const HeaderTitleContainer = styled.div`
    margin: 1.5rem 0rem 0.5rem 0rem;
`;

const MostViewedGuidesContainer = styled.div`
    margin-bottom: 1rem;
    margin: 0 2rem;
`;

const OtherGuidesContainer = styled.div`
    background-color: ${theme.colors.secondary.black};
    padding: 2rem 2rem 1.25rem 2rem;
    > h5 {
        color: ${theme.colors.secondary.white};
    }
`;

const GuidesHeader: FC = () => (
    <GuidesHeaderContainer>
        <Breadcrumbs
            breadcrumbs={[
                {
                    text: 'Security center',
                    path: RoutePath.SecurityCenter,
                },
                {
                    text: 'Guides',
                },
            ]}
        />
        <HeaderTitleContainer>
            <Title size="large">Guides</Title>
        </HeaderTitleContainer>
        <Body>Step-by-step guides to help you protect your information.</Body>
    </GuidesHeaderContainer>
);

const GuidesBody: FC = () => {
    const { value: mostViewedGuidesValue, loading: mostViewedGuidesLoading } =
        useGuideSummaries({
            limit: 4,
            additionalParams: {
                'metadata.tags.sys.id[in]': ['vzPopularGuide'],
            },
        });

    const { value: otherGuidesValue, loading: otherGuidesLoading } =
        useGuideSummaries({
            additionalParams: {
                'metadata.tags.sys.id[nin]': ['vzPopularGuide'],
            },
        });

    const [mostViewedGuides, setMostViewedGuides] =
        useState<GuideSummaryEntry[]>();

    const [otherGuides, setOtherGuides] = useState<GuideSummaryEntry[]>();

    useEffect(() => {
        setMostViewedGuides(mostViewedGuidesValue?.items);
    }, [mostViewedGuidesValue, mostViewedGuidesLoading]);

    useEffect(() => {
        setOtherGuides(otherGuidesValue?.items);
    }, [otherGuidesValue, otherGuidesLoading]);

    return (
        <>
            <Loader active={mostViewedGuidesLoading || otherGuidesLoading} />
            <MostViewedGuidesContainer>
                <Title>Most viewed</Title>
                <StyledLine type="light" />
                <LinkList
                    displayBottomBorder={true}
                    items={
                        mostViewedGuides?.map(
                            ({
                                fields: {
                                    title,
                                    description,
                                    relatedGuideLink,
                                },
                            }) => ({
                                actionId: 'GuideListItem',
                                extraData: { GuideListItem: title },
                                text: title,
                                description: description,
                                path: `${RoutePath.Guides}/${relatedGuideLink?.sys.id}`,
                            })
                        ) || []
                    }
                />
            </MostViewedGuidesContainer>
            <OtherGuidesContainer>
                <Title>All other guides</Title>
                <StyledLine inverted type="light" />
                <LinkList
                    color={theme.colors.secondary.white}
                    displayBottomBorder={true}
                    items={
                        otherGuides?.map(
                            ({
                                fields: {
                                    title,
                                    description,
                                    relatedGuideLink,
                                },
                            }) => ({
                                actionId: 'GuideListItem',
                                extraData: { GuideListItem: title },
                                text: title,
                                description: description,
                                path: `${RoutePath.Guides}/${relatedGuideLink?.sys.id}`,
                            })
                        ) || []
                    }
                />
            </OtherGuidesContainer>
        </>
    );
};

export const GuidesPage: FC = () => (
    <PageLayout
        margin="1.5rem 0rem 7rem 0rem"
        pageName="GuidesPage"
        header={<GuidesHeader />}
        body={<GuidesBody />}
    />
);
