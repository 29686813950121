import { createContext, useContext } from 'react';

export type AuthenticationContextType = {
    accessToken?: string;
};

export const initialAuthenticationContext: AuthenticationContextType = {
    accessToken: undefined,
};

export const AuthenticationContext = createContext(
    initialAuthenticationContext
);
AuthenticationContext.displayName = 'AuthenticationContext';

export const useAuthentication = () => useContext(AuthenticationContext);
