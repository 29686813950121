import { AnalyticsEventModel, EventModelWriter } from 'shisell';

declare global {
    interface Window {
        FS: {
            identify: (id: string, properties: Record<string, unknown>) => void;
            setUserVars: (properties: Record<string, unknown>) => void;
            event: (name: string, properties: Record<string, unknown>) => void;
        };
    }
}

export const fullstoryWriter: EventModelWriter<void> = (
    e: AnalyticsEventModel
) => {
    if (!window.FS || e.MetaData?.IsTest) {
        return;
    }
    window.FS.event(`${e.Scope}_${e.Name}`, {
        ...e.Identities,
        ...e.ExtraData,
    });
};
