import { TextLink as VdsTextButton } from '@soluto-private/vds-buttons';
import { enrichAnalytics, withAnalyticOnEvent } from 'react-shisell';
import { withExtra } from 'shisell/extenders';

type TextButtonProps = {
    actionId: string;
    onClick?: (e: Event) => void;
    href?: string;
    primary?: boolean;
    secondary?: boolean;
};

const TextButton = withAnalyticOnEvent<'onClick', [], TextButtonProps>({
    eventName: 'onClick',
    analyticName: 'Click',
})(VdsTextButton);

export default enrichAnalytics<TextButtonProps>((dispatcher, { actionId }) =>
    dispatcher.extend(withExtra('ActionId', actionId))
)(TextButton);
