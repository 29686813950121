import React, { FC } from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Body, Title } from '@soluto-private/vds-typography';
import styled from 'styled-components';

import { Button, theme } from '../../core-ui';
import { ExpertChatCTA } from '../../ctas';
import { DailytaskEntry } from '@security-app/contentful-context';
import { Document } from '@contentful/rich-text-types';

export type Props = {
    tip: DailytaskEntry;
    onClick: () => void;
};

const WrappedButton = styled(Button)`
    margin-top: 2rem;
`;

const Time = styled.div`
    margin-top: 0.5rem;
`;

const StyledExpertChatContainer = styled.div`
    margin-top: 1rem;
`;

export const UncompletedTip: FC<Props> = ({
    tip: {
        fields: { title, estimatedTime, taskdescription, id },
    },
    onClick,
}) => (
    <>
        <Title size="medium">{title}</Title>
        <Time>
            <Body color={theme.colors.greys.dark}>{estimatedTime}</Body>
        </Time>
        <Body>
            {taskdescription &&
                documentToReactComponents(taskdescription as Document)}
        </Body>
        <StyledExpertChatContainer>
            <ExpertChatCTA />
        </StyledExpertChatContainer>
        <WrappedButton
            onClick={onClick}
            actionId="TipCompleted"
            extraData={{ TipId: id }}
        >
            Mark as complete
        </WrappedButton>
    </>
);
