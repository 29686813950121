import { FC } from 'react';
import styled from 'styled-components';
import { useAnalytics } from 'react-shisell';

type ButtonWrapperProps = {
    actionId: string;
    extraData?: Record<string, unknown>;
    onClick: () => void;
    className?: string;
};

const ButtonContainer = styled.div`
    cursor: pointer;
`;

export const ButtonWrapper: FC<ButtonWrapperProps> = ({
    children,
    onClick,
    actionId,
    extraData,
    className,
}) => {
    const { dispatcher } = useAnalytics();
    const wrappedOnClick = () => {
        dispatcher.dispatch('onClick', {
            ExtraData: {
                ActionId: actionId,
                ...extraData,
            },
        });
        onClick();
    };

    return (
        <ButtonContainer className={className} onClick={wrappedOnClick}>
            {children}
        </ButtonContainer>
    );
};
