import { createClient } from 'contentful';

export const createContentfulProductClient = () => {
    const config = {
        accessToken: process.env.REACT_APP_CONTENTFUL_PC_TOKEN!,
        environment: process.env.REACT_APP_CONTENTFUL_ENV!,
        space: process.env.REACT_APP_CONTENTFUL_PC_SPACE!,
    };
    return createClient(config);
};
