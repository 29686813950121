import { FC } from 'react';
import { CategoryTags } from '../../categories/config';
import styled from 'styled-components';
import { Title, Body } from '@soluto-private/vds-typography';
import { CategoryContainer } from './Categories';
import { ArticleSection } from '../../articles/components';

const StyledTitleContainer = styled.div`
    margin: 1.5rem 2rem 0 2rem;
`;

const PopularTextContainer = styled.div`
    margin: 2.5rem 0 0 2rem;
`;

export const PopularArticles: FC = () => (
    <>
        <StyledTitleContainer>
            <Title size="medium">Articles</Title>
        </StyledTitleContainer>
        <CategoryContainer />
        <PopularTextContainer>
            <Body bold>Popular</Body>
        </PopularTextContainer>
        <ArticleSection
            tags={[CategoryTags.popular, CategoryTags.securityAdvisor]}
            limit={3}
        />
    </>
);
