import { EntrySkeletonType } from 'contentful';
import { ContentParams, useProductContent } from '../../contentful';

type Params = Omit<ContentParams, 'contentType'>;

export const useArticles = <T extends EntrySkeletonType>(params: Params = {}) =>
    useProductContent<T>({
        contentType: 'article',
        ...params,
    });
