interface Map {
    [key: string]: string;
}

export const exchangeSubjectToken = async (subjectToken: string) => {
    const exchangeParameters: Map = {
        grant_type: 'urn:ietf:params:oauth:grant-type:token-exchange',
        subject_token: subjectToken,
        subject_token_type: 'urn:ietf:params:oauth:token-type:access_token',
    };

    const rawFormBody: string[] = [];
    for (const property in exchangeParameters) {
        const encodedKey = encodeURIComponent(property);
        const encodedValue = encodeURIComponent(exchangeParameters[property]);
        rawFormBody.push(`${encodedKey}=${encodedValue}`);
    }
    const formBody = rawFormBody.join('&');
    const exchangeUrl = process.env.REACT_APP_TOKEN_EXCHANGE_API!;

    const response = await fetch(exchangeUrl, {
        headers: {
            'content-type': 'application/x-www-form-urlencoded',
        },
        body: formBody,
        method: 'POST',
    });
    const exchangeData = await response.json();
    const accessToken = exchangeData.access_token;

    return accessToken;
};
