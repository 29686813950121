import styled from 'styled-components';

import { ButtonGroupProps } from 'react-multi-carousel/lib/types';
import 'react-multi-carousel/lib/styles.css';

import Icon from '@soluto-private/vds-icons';
import { Body } from '@soluto-private/vds-typography';
import { ButtonWrapper } from '..';

interface CarouselButtonGroupProps extends ButtonGroupProps {
    className?: string;
}

const ButtonGroupWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const StyledBody = styled.div`
    margin: 0rem 1.5rem;
`;

const StyledButtonWrapper = styled(ButtonWrapper)`
    min-width: 1rem;
`;

export const CarouselButtonGroup = ({
    next,
    previous,
    carouselState,
    ...rest
}: CarouselButtonGroupProps) => {
    const { currentSlide = 0, totalItems = 1 } = carouselState || {};
    const currentSlideText = `${currentSlide + 1}/${totalItems}`;
    const showLeftArrow = previous && currentSlide !== 0;
    const showRightArrow = next && currentSlide !== totalItems - 1;

    return (
        <ButtonGroupWrapper>
            <StyledButtonWrapper
                actionId="PreviousStep"
                onClick={() => previous!()}
            >
                {showLeftArrow && <Icon name="arrow-left" size="extraSmall" />}
            </StyledButtonWrapper>
            <StyledBody>
                <Body size="small">{currentSlideText}</Body>
            </StyledBody>
            <StyledButtonWrapper actionId="NextStep" onClick={() => next!()}>
                {showRightArrow && (
                    <Icon name="arrow-right" size="extraSmall" />
                )}
            </StyledButtonWrapper>
        </ButtonGroupWrapper>
    );
};
