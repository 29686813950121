import { useState } from 'react';
import { ErrorContext } from './ErrorContext';

export const withErrorContextProvider =
    <P extends Record<string, never>>(
        Wrapped: React.ComponentType<P>
    ): React.FC<P> =>
    (props: P) => {
        const [error, setError] = useState<Error>();

        return (
            <ErrorContext.Provider value={{ error, setError }}>
                <Wrapped {...props} />
            </ErrorContext.Provider>
        );
    };
