import { ComponentType, FC } from 'react';
import { TweekProvider } from 'react-tweek';
import { useAsync } from 'react-use';
import { createTweekClient } from './createTweekClient';
import { createTweekRepository } from './createTweekRepository';
import { createTweekStore } from './createTweekStore';

export const withTweekProvider =
    <P extends {}>(Wrapped: ComponentType<P>): FC<P> =>
    (props: P) => {
        const { value: repository } = useAsync(async () => {
            const client = createTweekClient();
            const store = createTweekStore();
            return await createTweekRepository(client, store);
        }, []);

        return (
            <TweekProvider value={repository}>
                <Wrapped {...props} />
            </TweekProvider>
        );
    };
