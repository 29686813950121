import { analytics } from 'react-shisell';
import { createRootDispatcher } from 'shisell';
import {
    createScoped,
    withExtra,
    withIdentity,
    withMeta,
} from 'shisell/extenders';

import {
    analyticsWriters,
    AggregateWriter,
    UserBrowser,
} from '../../analytics';

const PARTNER = 'verizon';
const IS_PRODUCTION = process.env.REACT_APP_ENV === 'production';
const VERSION = process.env.REACT_APP_VERSION || 'undefined';

export default function initializeAnalytics() {
    const writer = new AggregateWriter([...analyticsWriters]);
    const userBrowser = UserBrowser.get();

    analytics.transformDispatcher(() =>
        createRootDispatcher(writer.write).extend(
            createScoped('DigitalSecure'),
            withIdentity('UserBrowserId', userBrowser.UserBrowserId),
            withExtra('AppName', 'digital-secure'),
            withExtra('EnvironmentPartner', PARTNER),
            withExtra('CarrierName', PARTNER),
            withMeta('IsTest', !IS_PRODUCTION),
            withExtra('IsTest', !IS_PRODUCTION),
            withExtra('Version', VERSION),
            withExtra('OsType', userBrowser.Os),
            withExtra('BrowserType', userBrowser.BrowserType),
            withExtra('BrowserVersion', userBrowser.BrowserVersion),
            withExtra('ScreenWidth', window.screen.width),
            withExtra('ScreenHeight', window.screen.height),
            withExtra('DeviceFormFactor', userBrowser.FormFactor),
            withExtra('CookiesEnabled', userBrowser.CookiesEnabled),
            withExtra('NavigatorLanguage', navigator.language),
            withExtra('NavigatorAppName', navigator.appName),
            withExtra('NavigatorAppCodeName', navigator.appCodeName),
            withExtra('UserAgent', userBrowser.UserAgent)
        )
    );
}
