import { FC } from 'react';

import { RouteChildrenProps } from 'react-router-dom';
import { Title } from '@soluto-private/vds-typography';
import { Line } from '@soluto-private/vds-lines';

import { PageLayout } from '../../../core-ui/PageLayout/PageLayout';
import { RoutePath } from '../../../navigation';
import { useSecurityContent } from '../../../contentful';
import { GuideSkeleton, GuideSummary } from '../../types';
import { Document } from '@contentful/rich-text-types';
import { GuidePaginatedList } from './GuidePaginatedList';
import styled from 'styled-components';
import { Breadcrumbs } from '../../../navigation/components';
import { Loader } from '@soluto-private/vds-loaders';

const GuidePageHeaderContainer = styled.div`
    padding: 0 2rem;
`;

const StyledLine = styled(Line)`
    margin: 0.75rem 0 0 0;
`;

type GuidePageHeaderProps = {
    guideName?: string;
    guideSummaryTitle?: string;
};

export const GuidePageHeader: FC<GuidePageHeaderProps> = ({
    guideName,
    guideSummaryTitle,
}) => (
    <GuidePageHeaderContainer>
        <Breadcrumbs
            breadcrumbs={[
                {
                    text: 'Guides',
                    path: RoutePath.Guides,
                },
                {
                    text: guideSummaryTitle || '',
                },
            ]}
            margin="0 2rem 2rem 0"
        />
        <Title size="large">{guideName}</Title>
        <StyledLine />
    </GuidePageHeaderContainer>
);

export type GuidePageProps = {
    id: string;
};

export const GuidePage: FC<RouteChildrenProps<GuidePageProps>> = ({
    match,
}) => {
    const { id } = match?.params || {};

    const { value: guideSummaryValue } = useSecurityContent<GuideSummary>({
        contentType: 'securityGuideSummary',
        additionalParams: {
            links_to_entry: id,
        },
    });

    const { value, loading } = useSecurityContent<GuideSkeleton>({
        contentType: 'securityGuide',
        additionalParams: {
            'sys.id': id,
        },
    });

    const guide = value?.items[0];
    const guideSummary = guideSummaryValue?.items[0];
    const shouldDisplayPage = !loading && !!guide && !!guideSummary;

    return (
        <>
            <Loader active={!shouldDisplayPage} />
            {shouldDisplayPage && (
                <PageLayout
                    margin="1.5rem 0rem 7rem 0rem"
                    pageName="GuidePage"
                    header={
                        <GuidePageHeader
                            guideName={guide.fields.guideOverviewTitle}
                            guideSummaryTitle={guideSummary.fields.title}
                        />
                    }
                    body={
                        <GuidePaginatedList
                            guideSteps={guide.fields.guideSteps}
                            guideOverview={
                                guide.fields
                                    .guideOverviewDescription as Document
                            }
                        />
                    }
                />
            )}
        </>
    );
};
