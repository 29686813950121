import React, { FC } from 'react';
import styled from 'styled-components';
import { Title, Body } from '@soluto-private/vds-typography';
import { MarkAsHelpful } from './MarkAsHelpful';
import { DailytaskEntry } from '@security-app/contentful-context';

const WrappedBody = styled.div`
    margin: 0.5rem 0 1rem 0;
`;

type CompletedTipProps = {
    tip: DailytaskEntry;
};

export const CompletedTip: FC<CompletedTipProps> = ({ tip }) => (
    <>
        <Title>Nice work!</Title>
        <WrappedBody>
            <Body>Check back later for your next goal. </Body>
            <MarkAsHelpful
                id={`tip-${tip.fields.id}`}
                extraData={{ TipId: tip.fields.id }}
                text="Was this helpful?"
            />
        </WrappedBody>
    </>
);
