import { AnalyticsEventModel, EventModelWriter } from 'shisell';

export default class AggregateWriter {
    writers: EventModelWriter<void>[];
    constructor(writers: EventModelWriter<void>[]) {
        this.writers = writers;
        this.write = this.write.bind(this);
    }

    async write(model: AnalyticsEventModel): Promise<void> {
        try {
            await Promise.all(this.writers.map((write) => write(model)));
        } catch (err) {
            console.warn(
                'failed sending analytic event',
                // @ts-ignore
                err.message ? err.message : err
            );
        }
    }
}
