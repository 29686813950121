import { useEffect, useState } from 'react';

import { SecurityAdvisorBridge, SecurityAdvisorBridgeV2 } from '../../bridge';
import { isInV2 } from '../../utils/isInV2';

export const useIsAuthenticated = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const bridge = isInV2() ? SecurityAdvisorBridgeV2 : SecurityAdvisorBridge;

    useEffect(() => {
        const subscription = bridge.accessToken$.subscribe((v: string) =>
            setIsAuthenticated(!!v)
        );
        return () => subscription.unsubscribe();
    });

    return isAuthenticated;
};
