import { FC } from 'react';
import { Line } from '@soluto-private/vds-lines';
import { Loader } from '@soluto-private/vds-loaders';
import { Body } from '@soluto-private/vds-typography';
import { RouteChildrenProps } from 'react-router-dom';
import styled from 'styled-components';

import { ChatLine } from './ChatLine';
import { useChatSessionMessages } from '../../hooks';
import { Button, PageLayout } from '../../../core-ui';
import { RoutePath } from '../../../navigation';

const StyledLine = styled(Line)`
    margin: 2rem 0 1rem 0;
`;

const CenteredContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const StyledButton = styled(Button)`
    margin: 1.5rem 0;
`;

type ChatHistorySessionProps = {
    id?: string;
};

const ChatHistorySessionBody: FC<ChatHistorySessionProps> = ({ id }) => {
    const messages = useChatSessionMessages(id);

    return !messages ? (
        <Loader active={true} />
    ) : (
        <div>
            {messages.map((m, i, ar) => (
                <ChatLine
                    key={`ChatLine-${m.id}`}
                    message={m}
                    isUser={m.sender === ar[0].sender}
                    displaySender={i === 0 || m.sender !== ar[i - 1].sender}
                />
            ))}
            <StyledLine />
            <CenteredContainer>
                <Body>Still need help? Chat with us.</Body>
                <StyledButton
                    actionId="StartChatButton"
                    href={process.env.REACT_APP_CHAT_SMART_LINK}
                >
                    Start chat
                </StyledButton>
            </CenteredContainer>
        </div>
    );
};

export const ChatHistorySessionPage: FC<
    RouteChildrenProps<ChatHistorySessionProps>
> = ({ match }) => {
    const { id } = match?.params || {};

    return (
        <PageLayout
            pageName={`ChatHistorySessionPage-${id}`}
            breadcrumbs={[
                {
                    text: 'Chat history',
                    path: RoutePath.ChatHistory,
                },
                { text: 'Past conversation' },
            ]}
            body={<ChatHistorySessionBody id={id} />}
            margin="1.5rem 1rem"
        />
    );
};
