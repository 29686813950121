import React, { FC } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { Body } from '@soluto-private/vds-typography';
import { ActionCard, SideScroll } from '../../core-ui';
import { categories } from '../config';

export type Category = {
    title: string;
    url: string;
};

const StyledActionCard = styled(ActionCard)`
    flex: 0 0 auto;
    margin-right: 0.5rem;
    min-width: 9.375rem;
    padding: 0.75rem;
    white-space: pre;
    background-color: ${(props) => props.theme.colors.greys.light};
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

const WrappedSubtitle = styled.div`
    margin: 0.75rem 0 1rem 2rem;
`;

const Categories: FC = () => {
    const history = useHistory();
    const handleClick = (path: string = '/') => {
        history.push(path);
    };

    return (
        <SideScroll
            component={
                <>
                    {categories.map(({ title, url }, index) => (
                        <StyledActionCard
                            body={title}
                            bodyBold={true}
                            key={`title-${index}`}
                            onClick={() => handleClick(url)}
                            actionId="Category"
                            extraData={{ Category: title }}
                            iconSize="small"
                        ></StyledActionCard>
                    ))}
                </>
            }
        ></SideScroll>
    );
};

export const CategoryContainer: FC = () => (
    <Container>
        <WrappedSubtitle>
            <Body viewport="mobile" primitive="h6">
                Categories
            </Body>
        </WrappedSubtitle>

        <Categories />
    </Container>
);
