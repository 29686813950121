import React, { FC } from 'react';
import styled from 'styled-components';
import { Title, Body } from '@soluto-private/vds-typography';
import { RouteChildrenProps } from 'react-router-dom';
import { PageLayout } from '../core-ui';
import { VideoSection } from '../video';
import { RoutePath } from '../navigation';
import {
    categories,
    Category,
    CategoryDescription,
    CategoryTags,
    CategoryTitle,
} from './config';
import { ArticleSection } from '../articles/components/ArticleSection';

type HeaderProps = {
    category: Category;
};

type SelectedCategoryPageProps = {
    category: Category;
};

const TitleContainer = styled.div`
    margin: 1.5rem 2rem 0 2rem;
`;

const SectionTitleContainer = styled.div`
    margin: 1.5rem 2rem 0 2rem;
`;

const VideoTitleContainer = styled(SectionTitleContainer)`
    margin-bottom: 1rem;
`;

const WrappedBody = styled.div`
    margin-top: 0.75rem;
`;

const GreySection = styled.div`
    height: 0.75rem;
    margin-top: 0.5rem;
    background-color: ${({ theme }) => theme.colors.greys.light};
`;

const Header: FC<HeaderProps> = ({ category }) => (
    <TitleContainer>
        <Title size="large">{CategoryTitle[category]}</Title>
        <WrappedBody>
            <Body size="small">{CategoryDescription[category]}</Body>
        </WrappedBody>
    </TitleContainer>
);

export const SelectedCategoryPage: FC<
    RouteChildrenProps<SelectedCategoryPageProps>
> = ({ match }) => {
    const { category = 'bestPractices' } = match?.params || {};
    const formattedCategory = categories.includes(category)
        ? category
        : 'bestPractices';

    return (
        <PageLayout
            pageName={formattedCategory}
            breadcrumbs={[
                { text: 'Security center', path: RoutePath.SecurityCenter },
                { text: `${CategoryTitle[category]}` },
            ]}
            breadcrumbMargin="1.5rem 0 0 2rem"
            header={<Header category={formattedCategory} />}
            margin="0"
            body={
                <>
                    <SectionTitleContainer>
                        <Title size="medium">Articles</Title>
                    </SectionTitleContainer>
                    <ArticleSection
                        tags={[
                            CategoryTags[formattedCategory],
                            CategoryTags.securityAdvisor,
                        ]}
                    ></ArticleSection>
                    <GreySection />
                    <VideoTitleContainer>
                        <Title size="medium">Related videos</Title>
                    </VideoTitleContainer>
                    <VideoSection
                        categoryTag={CategoryTags[formattedCategory]}
                    />
                </>
            }
        />
    );
};
