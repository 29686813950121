import React, { FC } from 'react';
import styled from 'styled-components';
import { DidYouKnowTipsCard } from './DidYouKnowTipsCard';
import { PaginatedCarousel } from '../../core-ui';
import { useSecurityContent } from '../../contentful';
import { EntryFieldTypes } from 'contentful';

export type ShortTip = {
    contentTypeId: 'shortTip';
    fields: {
        id: EntryFieldTypes.Symbol;
        description: EntryFieldTypes.Symbol;
    };
};

const DidYouKnowTipsContainer = styled.div`
    max-width: 100vw;
`;

export const DidYouKnowTips: FC = () => {
    const { value: allTips } = useSecurityContent<ShortTip>({
        contentType: 'shortTip',
        limit: 4,
    });

    return (
        <DidYouKnowTipsContainer>
            <PaginatedCarousel itemClass="small-width">
                {allTips?.items.map((tip) => (
                    <DidYouKnowTipsCard
                        key={tip.fields.id}
                        tipId={tip.fields.id}
                        taskDescription={tip.fields.description}
                    />
                )) || <></>}
            </PaginatedCarousel>
        </DidYouKnowTipsContainer>
    );
};
