import { SecurityAdvisorBridge, SecurityAdvisorBridgeV2 } from '../../bridge';

enum BridgeKeys {
    AuthToken = 'SA_T',
}

export function initializeBridge() {
    const localToken = window.sessionStorage.getItem(BridgeKeys.AuthToken);
    SecurityAdvisorBridge.accessToken$.subscribe((v: string) =>
        window.sessionStorage.setItem(BridgeKeys.AuthToken, v)
    );
    if (!SecurityAdvisorBridge.accessToken$.getValue() && localToken) {
        SecurityAdvisorBridge.accessToken$.next(localToken);
    }
    window.SECURITY_ADVISOR = SecurityAdvisorBridge;
}

export function initializeBridgeV2() {
    const localToken = window.sessionStorage.getItem(BridgeKeys.AuthToken);
    window.sessionStorage.removeItem(BridgeKeys.AuthToken);

    SecurityAdvisorBridgeV2.accessToken$.subscribe((v: string) =>
        window.sessionStorage.setItem(BridgeKeys.AuthToken, v)
    );

    if (!SecurityAdvisorBridgeV2.accessToken$.getValue() && localToken) {
        console.log('signing in with local token');
        SecurityAdvisorBridgeV2.signIn(localToken);
    }
    window.SECURITY_ADVISOR = SecurityAdvisorBridgeV2;
}

declare global {
    interface Window {
        SECURITY_ADVISOR: typeof SecurityAdvisorBridge;
    }
}
