import { EventModelWriter } from 'shisell';

export const apiWriter: EventModelWriter<void> = async (e) => {
    try {
        await fetch(
            'https://prodhome1analyticslistener.mysoluto.com/api/v1/Events/',
            {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(e),
            }
        );
    } catch (error) {
        console.warn('Could not write analytic to backend', {
            error,
            e,
        });
    }
};
