// @ts-nocheck
import { BehaviorSubject } from 'rxjs';
import * as jose from 'jose';
import { exchangeSubjectToken } from '../utils/tokenExchangeHelper';

export class SecurityAdvisorBridge {
    public static readonly accessToken$ = new BehaviorSubject<string>('');
    public static readonly signIn = function (accessToken: string) {
        SecurityAdvisorBridge.accessToken$.next(accessToken);
    };
}

export class SecurityAdvisorBridgeV2 {
    public static readonly accessToken$ = new BehaviorSubject<string>('');
    private static readonly _signIn = async function (
        verizonAccessToken: string
    ) {
        const JWKS = jose.createRemoteJWKSet(
            new URL(process.env.REACT_APP_ASURION_JWKS_URL!)
        );

        try {
            const asurionIdToken = await exchangeSubjectToken(
                verizonAccessToken
            );
            await jose.jwtVerify(asurionIdToken, JWKS, {
                issuer: process.env.REACT_APP_ASURION_JWT_ISSUER!,
            });

            this.accessToken$.next(verizonAccessToken);
        } catch (error) {
            console.error('error', error);
            this.accessToken$.next();
        }
    };
    public static get signIn() {
        return SecurityAdvisorBridgeV2._signIn;
    }
}
