import { RoutePath } from '../navigation';
import { Category } from './security-center-ui/Categories';

export const categories: Category[] = [
    {
        title: 'Account\nSecurity',
        url: `${RoutePath.Category}/accountSecurity`,
    },
    {
        title: 'Best\nPractices',
        url: `${RoutePath.Category}/bestPractices`,
    },
    {
        title: 'Frauds &\nScams',
        url: `${RoutePath.Category}/fraudsAndScams`,
    },
    {
        title: 'Network\nSafety',
        url: `${RoutePath.Category}/networkSafety`,
    },
    {
        title: 'Identity\nProtection',
        url: `${RoutePath.Category}/identityProtection`,
    },
];

export const pageSectionTabs = [
    { label: 'Guides', name: 'guides' },
    {
        label: 'Common questions',
        name: 'common-questions',
    },
    {
        label: "Today's tip",
        name: 'todays-tip',
    },
    {
        label: 'Articles',
        name: 'articles',
    },
    { label: 'Videos', name: 'videos' },
    {
        label: 'Did you know',
        name: 'did-you-know',
    },
];

export enum SectionNames {
    Guides = 0,
    CommonQuestions = 1,
    TodaysTip = 2,
    Articles = 3,
    Videos = 4,
    DidYouKnow = 5,
}
