export enum RoutePath {
    Articles = '/articles',
    ChatHistory = '/chat-history',
    Error = '/error',
    Guides = '/guides',
    Home = '/',
    Onboarding = '/onboarding',
    SecurityCenter = '/security-center',
    Video = '/video',
    Category = '/category',
    TodaysTip = '/todays-tip',
    V2 = '/v2',
}
