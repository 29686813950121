import { useAsyncRetry } from 'react-use';

import { ChatSessionMessage } from '../ChatSessionMessage';
import { useErrorHandling } from '../../error-handling';
import { useAuthentication } from '../../authentication';

export const useChatSessionMessages = (sessionId?: string) => {
    const { accessToken } = useAuthentication();

    const state = useAsyncRetry(async () => {
        if (sessionId && accessToken) {
            const url = `${process.env.REACT_APP_SECURITY_API}/ds-chat-sessions/sessions/${sessionId}/messages`;
            const opts: RequestInit = {
                method: 'GET',
                headers: { authorization: `Bearer ${accessToken}` },
            };
            const response = await fetch(url, opts);
            if (!response.ok) {
                throw new Error(
                    `Failed to fetch chat messages: ${response.statusText}`
                );
            }
            // eslint-disable-next-line
            const messages = (await response.json()) as Record<string, any>[];
            const formatted = messages
                .filter((m) => m.payload.type === 'text_message')
                .sort((a, b) => a.sentAt - b.sentAt)
                .map((m, i, ar) => {
                    const { senderId: userId } = ar[0];
                    return {
                        id: m.messageId,
                        senderId: m.senderId,
                        recipientId: m.recipientId,
                        sentAt: m.sentAt,
                        sender:
                            userId === m.senderId
                                ? 'You'
                                : m.metadata?.senderName.trim() || 'Team',
                        text: m.payload.value,
                    } as ChatSessionMessage;
                });
            return formatted;
        }
    }, [sessionId, accessToken]);

    useErrorHandling({ state });

    return state.value;
};
