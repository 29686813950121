import React, { FC } from 'react';
import { PageLayout } from '../../core-ui';
import { Title } from '@soluto-private/vds-typography';
import { VideoSection } from './VideoSection';
import styled from 'styled-components';
import { RoutePath } from '../../navigation';
import { Breadcrumbs } from '../../navigation/components';

const StyledHeader = styled.div`
    margin: 0 0 0.5rem 2rem;
`;

const VideoHeader: FC = () => (
    <StyledHeader>
        <Breadcrumbs
            breadcrumbs={[
                { text: 'Security center', path: RoutePath.SecurityCenter },
                { text: 'All videos' },
            ]}
            margin="0 0 1.5rem 0"
        />
        <Title size="large">All videos</Title>
    </StyledHeader>
);

const VideoPage: FC = () => (
    <PageLayout
        pageName="VideoPage"
        header={<VideoHeader />}
        body={<VideoSection />}
        margin="1.5rem 0"
    />
);

export default VideoPage;
