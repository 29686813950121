/* eslint-disable @typescript-eslint/no-explicit-any */
import Cookies from 'js-cookie';
const UAParser: any = require('ua-parser-js');
const uuidv4 = require('uuid/v4');

const UNKNOWN_VALUE = 'Unknown';
const USER_BROWSER_ID_KEY = 'UserBrowserId';
const SESSION_QUERY_PARAMETERS_KEY = 'session-query-parameters';

export default class UserBrowser {
    protected static instance: UserBrowser | undefined;

    static Partner?: string;

    UserBrowserId: string;
    Os: string;
    OsVersion: string;
    Vendor: string;
    Model: string;
    BrowserType: string;
    BrowserVersion: string;
    FormFactor: string;
    sessionQueryParameters: any;
    UserAgent: string;
    CookiesEnabled: boolean;

    protected constructor() {
        this.initQueryParameters();

        const ua = new UAParser().getResult();
        this.Os = ua.os.name || UNKNOWN_VALUE;
        this.OsVersion = ua.os.version || UNKNOWN_VALUE;
        this.Vendor = ua.device.vendor || UNKNOWN_VALUE;
        this.Model = ua.device.model || UNKNOWN_VALUE;
        this.BrowserType = ua.browser.name || UNKNOWN_VALUE;
        this.BrowserVersion = ua.browser.major || UNKNOWN_VALUE;
        this.FormFactor =
            (ua.device.type === 'tablet' && 'Tablet') ||
            (ua.device.type === 'mobile' && 'Mobile') ||
            'Desktop';
        this.UserAgent = navigator.userAgent;
        this.CookiesEnabled = navigator.cookieEnabled;
        this.UserBrowserId = this.retrieveId();
    }

    private retrieveId(): string {
        let id = undefined;
        // the ID used to be saved in a cookie, but due to Safari's limit for cookie expiration we switched to localStorage. For backward compatibility, use the value from cookie if exists
        id = Cookies.get(USER_BROWSER_ID_KEY);

        if (!id) {
            id = localStorage.getItem(USER_BROWSER_ID_KEY);
        }

        if (!id) {
            id = uuidv4();
        }
        try {
            localStorage.setItem(USER_BROWSER_ID_KEY, id);
        } catch {}

        return id;
    }

    private initQueryParameters() {
        let sessionQueryParameters = sessionStorage.getItem(
            SESSION_QUERY_PARAMETERS_KEY
        );
        if (!sessionQueryParameters) {
            sessionQueryParameters = window.location.search;
            try {
                sessionStorage.setItem(
                    SESSION_QUERY_PARAMETERS_KEY,
                    sessionQueryParameters
                );
            } catch {}
        }

        if (window.URLSearchParams) {
            this.sessionQueryParameters = new window.URLSearchParams(
                sessionQueryParameters
            );
        }
    }

    public static get(): UserBrowser {
        if (!UserBrowser.instance) {
            UserBrowser.instance = new UserBrowser();
        }

        return UserBrowser.instance;
    }

    protected static clearInstance() {
        UserBrowser.instance = undefined;
    }

    public isIE() {
        return this.BrowserType
            ? this.BrowserType.toLowerCase() === 'ie'
            : false;
    }
}
