import { createTweekClient as createClient, FetchError } from 'tweek-client';

export const createTweekClient = () =>
    createClient({
        baseServiceUrl: 'https://tweek.mysoluto.com',
        useLegacyEndpoint: true,
        onError: (error: Error) => {
            console.error(
                error instanceof FetchError
                    ? `tweek error ${error.response.status} - ${error.response.statusText}`
                    : `tweek error`,
                error
            );
        },
    });
