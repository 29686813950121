import { FC } from 'react';
import Icon from '@soluto-private/vds-icons';
import { Body } from '@soluto-private/vds-typography';
import styled from 'styled-components';
import { Link } from '../Link';
import { theme } from '../Theme';

export type LinkListItemProps = {
    actionId: string;
    extraData?: Record<string, unknown>;
    text: string;
    description?: string;
    path: string;
    color?: string;
};

const TextLineContainer = styled.div`
    display: inline-flex;
`;

const BodyContainer = styled.div`
    flex-grow: 1;
`;

const DescriptionContainer = styled.div`
    width: 80%;
`;

const StyledLink = styled(Link)`
    display: flex;
    flex-direction: column;
    text-decoration: none;
    > * {
        color: ${(props) =>
            props.color ? props.color : theme.colors.secondary.black};
    }
    & ${TextLineContainer} {
        > * {
            color: ${(props) =>
                props.color ? props.color : theme.colors.secondary.black};
        }
    }
    & ${BodyContainer} {
        > * {
            color: ${(props) =>
                props.color ? props.color : theme.colors.secondary.black};
        }
    }
`;

export const LinkListItem: FC<LinkListItemProps> = (props) => (
    <StyledLink
        actionId={props.actionId}
        extraData={props.extraData}
        to={props.path}
        color={props.color}
    >
        <TextLineContainer>
            <BodyContainer>
                <Body>
                    <strong>{props.text}</strong>
                </Body>
            </BodyContainer>
            <Icon
                name="caret-right"
                size="extraSmall"
                color={props.color ? props.color : theme.colors.secondary.black}
            />
        </TextLineContainer>
        <DescriptionContainer>
            <Body>{props.description}</Body>
        </DescriptionContainer>
    </StyledLink>
);
