import { FC } from 'react';
import styled from 'styled-components';

import chatBubble from './chat-bubble.svg';
import { ExperimentVersion } from '../ExperimentVersion';
import { Button, Link } from '../../../../../../core-ui';
import { RoutePath } from '../../../../../../navigation';

type Props = {
    experimentVersion: ExperimentVersion;
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding-top: 1.25rem;
    margin-bottom: 0.25rem;
`;

const Row = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 0.75rem;
`;

const StyledButton = styled(Button)`
    font-size: 1.25rem;
    font-weight: 700;
    height: auto;
    filter: drop-shadow(0px 4px 4px #747676);

    > span + span {
        padding: 0.5rem 1.25rem;
    }
`;

const ChatBubbleImg = styled.img`
    margin-right: 1.25rem;
`;

const StyledLink = styled(Link)`
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1rem;
    color: #000000;
`;

const ChatBubble: FC = () => (
    <ChatBubbleImg src={chatBubble} alt="Chat bubble" />
);

export const BigChatButton: FC<Props> = ({
    experimentVersion: ExperimentVersion,
}) => (
    <Container>
        <Row>
            <StyledButton
                actionId="StartChatButton"
                href={process.env.REACT_APP_CHAT_SMART_LINK}
                extraData={{
                    Experiment: 'HomePageChatCtaExperiment',
                    ExperimentVersion,
                }}
                ariaLabel="Chat with us"
            >
                <ChatBubble />
                Chat with us
            </StyledButton>
        </Row>
        <Row>
            <StyledLink
                actionId="ChatHistoryLink"
                extraData={{
                    Experiment: 'HomePageChatCtaExperiment',
                    ExperimentVersion,
                }}
                to={RoutePath.ChatHistory}
            >
                Chat history
            </StyledLink>
        </Row>
    </Container>
);
