import React from 'react';
import ReactDOM from 'react-dom';

import './index.css';
import reportWebVitals from './reportWebVitals';
import { App } from './main/components';
import initialize from './main/initialization';
// required to enable smooth scroll on safari, ios webview
import smoothscroll from 'smoothscroll-polyfill';

smoothscroll.polyfill();
const initPromise = initialize();
ReactDOM.render(
    <React.StrictMode>
        <App initPromise={initPromise} />
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
