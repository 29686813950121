import { MutableRefObject, useEffect, useState } from 'react';

const isRefTopVisible = (ref: MutableRefObject<HTMLDivElement>) => {
    const { top } = ref.current?.getBoundingClientRect() || {};
    return ref.current && top >= 0 && top < window.innerHeight;
};

const isRefBottomVisible = (ref: MutableRefObject<HTMLDivElement>) => {
    const { bottom } = ref.current?.getBoundingClientRect() || {};
    return ref.current && bottom >= 0 && bottom <= window.innerHeight;
};

const isRefVisible = (ref: MutableRefObject<HTMLDivElement>) => {
    const { top, bottom } = ref.current?.getBoundingClientRect() || {};
    return (
        ref.current &&
        (isRefTopVisible(ref) ||
            isRefBottomVisible(ref) ||
            (top < 0 && bottom >= window.innerHeight))
    );
};

const isRefAboveMiddle = (ref: MutableRefObject<HTMLDivElement>) =>
    ref.current &&
    ref.current.getBoundingClientRect().top < window.innerHeight / 2;

const getVisible = (refs: MutableRefObject<HTMLDivElement>[]) =>
    refs
        .map((ref, refIndex) => ({ ref, refIndex }))
        .filter(({ ref }) => isRefVisible(ref));

const getTopVisible = (refs: MutableRefObject<HTMLDivElement>[]) =>
    refs
        .map((ref, refIndex) => ({ ref, refIndex }))
        .filter(({ ref }) => isRefTopVisible(ref));

export const useActiveTab = (refs: MutableRefObject<HTMLDivElement>[]) => {
    const [activeTab, setActiveTab] = useState(0);

    useEffect(() => {
        const handleUpdate = (refIndex: number) => {
            if (activeTab !== refIndex) {
                setActiveTab(refIndex);
            }
        };

        const listener = () => {
            const visibleRefs = getVisible(refs);
            const topVisibleRefs = getTopVisible(refs);
            const lastRef = refs[refs.length - 1];
            const isBottomOfPage =
                lastRef.current &&
                lastRef.current.getBoundingClientRect().bottom <=
                    window.innerHeight;

            if (isBottomOfPage) {
                const refIndex = refs.length - 1;
                handleUpdate(refIndex);
            } else if (
                visibleRefs.length > 1 &&
                topVisibleRefs.length > 0 &&
                isRefAboveMiddle(topVisibleRefs[0].ref)
            ) {
                const { refIndex } = topVisibleRefs[0];
                handleUpdate(refIndex);
            } else if (visibleRefs.length > 0) {
                const { refIndex } = visibleRefs[0];
                handleUpdate(refIndex);
            }
        };
        window.addEventListener('scroll', listener);

        return () => window.removeEventListener('scroll', listener);
    }, [activeTab, refs]);

    return { activeTab, setActiveTab };
};
