import { isInV2 } from '../../utils/isInV2';
import initializeAnalytics from './analytics';
import { initializeBridge, initializeBridgeV2 } from './bridge';

type InitializeOpts = {};

const initialize = async (opts: InitializeOpts = {}) => {
    initializeAnalytics();

    if (isInV2()) {
        initializeBridgeV2();
    } else {
        initializeBridge();
    }

    await Promise.all([]);
};

export default initialize;
