import { FC } from 'react';
import {
    Breadcrumbs as VzBreadcrumbs,
    BreadcrumbItem,
} from '@soluto-private/vds-breadcrumbs';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { Breadcrumb } from '../../Breadcrumb';

export type BreadcrumbProps = {
    breadcrumbs: Breadcrumb[];
    margin?: string;
};

const StyledBreadcrumbs = styled(VzBreadcrumbs)`
    margin: ${({ margin }) => (margin ? margin : '0')};
`;

export const Breadcrumbs: FC<BreadcrumbProps> = ({ breadcrumbs, margin }) => {
    const history = useHistory();
    const truncateText = (text: string) =>
        text.length > 22 ? `${text.slice(0, 22)}...` : text;

    return (
        <StyledBreadcrumbs margin={margin}>
            {breadcrumbs.map(({ text, path }, index) => (
                <BreadcrumbItem key={`BreadcrumbItem-${index}`} href="">
                    {!!path ? (
                        <div onClick={() => history.push(path)}>
                            {truncateText(text)}
                        </div>
                    ) : (
                        truncateText(text)
                    )}
                </BreadcrumbItem>
            ))}
        </StyledBreadcrumbs>
    );
};
