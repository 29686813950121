import React, { useCallback, useEffect } from 'react';
import { useLocalStorage } from 'react-use';
import { useSecurityContent } from '../contentful';
import { DailytaskEntry, Dailytask } from '@security-app/contentful-context';
import { TodaysTipContext } from './useTodaysTip';

type TipSelection = {
    tip: DailytaskEntry;
    timestamp: string;
};

type TipCompletion = {
    completedTips: Record<string, { completed: boolean }>;
};

const shouldSelectNewTip = (selection?: TipSelection) => {
    if (selection) {
        const nextTipDate = new Date(selection.timestamp);
        nextTipDate.setHours(nextTipDate.getHours() + 12);
        return new Date() > nextTipDate;
    }
    return true;
};

const getRandomTip = (tips: DailytaskEntry[]): DailytaskEntry | undefined =>
    tips[Math.floor(Math.random() * tips.length)];

export const withTodaysTipProvider =
    <P extends {}>(Wrapped: React.ComponentType<P>): React.FC<P> =>
    (props: P) => {
        const { value: allTips } = useSecurityContent<Dailytask>({
            contentType: 'dailytaskContentModel',
            isRequiredContent: false,
        });

        const [tipSelection, setTipSelection] =
            useLocalStorage<TipSelection>('todaysTipSelection');

        const [{ completedTips } = { completedTips: {} }, setTipCompletion] =
            useLocalStorage<TipCompletion>('todaysTipCompletion');

        const todaysTip = tipSelection?.tip;

        const isTipCompleted =
            todaysTip && completedTips[todaysTip.fields?.id]
                ? completedTips[todaysTip.fields?.id].completed
                : false;

        const setTipCompleted = useCallback(() => {
            if (todaysTip) {
                setTipCompletion((prev) => ({
                    completedTips: {
                        ...prev?.completedTips,
                        [todaysTip.fields.id]: { completed: true },
                    },
                }));
            }
        }, [todaysTip, setTipCompletion]);

        useEffect(() => {
            if (shouldSelectNewTip(tipSelection)) {
                const availableTips =
                    allTips?.items.filter(
                        ({ fields: { id } }) => !completedTips[id]?.completed
                    ) || [];

                const randomTip = getRandomTip(availableTips);
                if (randomTip) {
                    setTipSelection({
                        tip: randomTip,
                        timestamp: new Date().toISOString(),
                    });
                }
            }
        }, [allTips, completedTips, tipSelection, setTipSelection]);

        return (
            <TodaysTipContext.Provider
                value={{
                    todaysTip,
                    isTipCompleted,
                    setTipCompleted,
                }}
            >
                <Wrapped {...props} />
            </TodaysTipContext.Provider>
        );
    };
