import { FC } from 'react';
import { Body } from '@soluto-private/vds-typography';
import styled from 'styled-components';

export type ItemProps = {
    text: string;
    description?: string;
};

const ItemContainer = styled.div`
    margin-bottom: 1em;
`;

export const Item: FC<ItemProps> = ({ text, description }) => (
    <ItemContainer>
        <Body size="large">
            <strong>{text}</strong>
        </Body>
        <Body>{description}</Body>
    </ItemContainer>
);
