import { FC } from 'react';
import { Redirect } from 'react-router-dom';
import { useErrorContext } from '../../../error-handling';
import { RoutePath } from '../../../navigation';
import { useHasOnboarded } from '../../../onboarding/hooks';

export const RedirectManager: FC = () => {
    const { onboarded } = useHasOnboarded();
    const { error } = useErrorContext();

    return (
        <>
            {error && <Redirect to={RoutePath.Error} />}
            {!onboarded && <Redirect to={RoutePath.Onboarding} />}
        </>
    );
};
